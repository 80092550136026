<template>
  <div class="toolbar__item toolbar__item--visible-action">
    <div class="toolbar__item-title">{{ name }}</div>
    <div
      style="width:141px"
      class="toolbar__item-action"
    >
      <ColorPickerInput
        v-model="color"
        @input="$event => $colorPicker.update(this._uid, $event)"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'ColorSettingsItem',

  props: {
    name: String,
    property: String,
    value: String
  },

  data: (vm) => ({
    color: vm.value
  }),

  watch: {
    value (value) {
      this.color = value
    },

    color (value) {
      this.update(value)
    }
  },

  methods: {
    onSelect () {
      const key = this.name.toLowerCase()
      const isGradientEnabled =
        key.includes('color') ||
        key.includes('background')
      this.$colorPicker.toggle(this._uid, this.color, isGradientEnabled, (color) => {
        this.color = color
      })
    },

    update: debounce(function (value) {
      const color = this.property
        ? { [this.property]: value }
        : value
      this.$emit('update', color)
    }, 200)
  }
}
</script>
