<template lang="pug">
.toolbar__item.toolbar__item--visible-action
  .toolbar__item-title {{ name }}
  .toolbar__item-action
    v-switch(
      inset
      dense
      hide-details
      style="margin-right: -15px"
      :value="value"
      :input-value="value"
      @change="$emit('update', !!$event)")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>
