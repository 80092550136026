<template lang="pug">
.config-list
  .toolbar__header
    .toolbar__title {{ item.name }}
    button.btn(@click="onCreate")
      v-icon mdi-plus
  draggable(
    v-model="item.subConfigs"
    @change="onSort")
    .config-list__wrapper(
      v-for="(config, index) in item.subConfigs"
      :key="index"
      :style="{ paddingRight: toggledItem === index ? '40px' : 0 }")
      .config-list__item(
        @click="onSelect($event, index, false)"
        @contextmenu.stop="onSelect($event, index, true)")
        config-items(
          sub
          :config="config"
          :configSelectedLang="configSelectedLang"
          @update="onUpdate($event, index, false)"
          @sub-update="onSubUpdate($event, index, false)")
      .config-list__actions
        v-btn(
          fab
          x-small
          elevation="0"
          @click="onDelete(index)")
          v-icon(
            small
            color="red")
            | mdi-delete
</template>

<script>
import draggable from 'vuedraggable'
import { deepClone, generateId } from '@/util/utils.js'

export default {
  name: 'ListConfigItem',

  components: {
    draggable
  },

  props: {
    item: Object,
    configSelectedLang: String
  },

  data: () => ({
    toggledItem: null
  }),

  methods: {
    onSort () {
      this.$emit('sub-update', this.item)
    },

    onSelect ($event, index, select) {
      if (select) {
        $event.preventDefault()
        this.toggledItem = index
      } else {
        this.toggledItem = null
      }
    },

    onUpdate ({ value, item }, index) {
      this.item.subConfigs[index][item.key].value = value
      this.$emit('sub-update', this.item)
    },

    onSubUpdate ({ key, ...data }, index) {
      this.item.subConfigs[index][key] = data
      this.$emit('sub-update', this.item)
    },

    onCreate () {
      const item = deepClone(this.item.template)
      for (const key in item) {
        if (Array.isArray(item[key].value)) {
          const index = this.item.subConfigs.length % item[key].value.length
          item[key].value = item[key].value[index]
        }
        if (item[key].type === 'UID') {
          item[key].value = generateId()
        }
      }
      if (!this.item.subConfigs) {
        this.item.subConfigs = []
      }
      this.item.subConfigs.push(item)
      this.$emit('sub-update', this.item)
    },

    onDelete (index) {
      this.toggledItem = null
      this.item.subConfigs.splice(index, 1)
      this.$emit('sub-update', this.item)
    }
  }
}
</script>

<style lang="scss">
.config-list {
  padding: 8px;
  padding-top: 4px;
  border-radius: 4px;
  margin-top: 8px;
  border: 1px solid var(--color-border);
  background-color: var(--color-foreground);

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &__wrapper {
    position: relative;
    transition: padding .5s;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item {
    position: relative;
    z-index: 2;
    cursor: pointer;
    padding: 6px;
    padding-top: 4px;
    border-radius: 4px;
    background-color: var(--color-surface);
    box-shadow: var(--elevation);
  }

  &__actions {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .toolbar__header {
    height: 24px;
    margin-bottom: 5px;
  }
}
</style>
