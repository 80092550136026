import { render, staticRenderFns } from "./LinkConfigItem.vue?vue&type=template&id=6e9f43b1&lang=pug"
import script from "./LinkConfigItem.vue?vue&type=script&lang=js"
export * from "./LinkConfigItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports