import api from '@/api'
import { isObjectEmpty, generateId } from '@/util/utils.js'
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  onSnapshot
} from 'firebase/firestore'
import {
  CREATE_WIDGET,
  UPDATE_WIDGET,
  REMOVE_WIDGET,
  ADD_DEFAULT_ITEMS_FOR_WIDGET
} from '@/modules/builder/utils'
import { populateWebsiteWithOrderFields } from '../utils/populateWebsiteWithOrderFields.js'

const db = getFirestore()
const WEBSITES_COLL = 'websites'
const SNAPSHOTS_COLL = 'snapshots'
const ORDERS_COLL = 'orders'

const SET_ORDER = 'SET_ORDER'
const SET_WEBSITE = 'SET_WEBSITE'
const ADD_UNSUBSCRIBE = 'ADD_UNSUBSCRIBE'
const REMOVE_UNSUBSCRIBES = 'REMOVE_UNSUBSCRIBES'
const SET_LOADED = 'SET_LOADED'

export default {
  namespaced: true,

  state: {
    order: null,
    website: null,
    unsubscribes: [],
    loaded: false
  },

  getters: {
    order: (state) => state.order,
    website: (state) => state.website,
    loaded: (state) => state.loaded
  },

  mutations: {
    [SET_ORDER] (state, value) {
      state.order = value
    },

    [SET_WEBSITE] (state, value) {
      state.website = value
    },

    [ADD_UNSUBSCRIBE] (state, value) {
      state.unsubscribes = [...state.unsubscribes, value]
    },

    [REMOVE_UNSUBSCRIBES] (state) {
      state.unsubscribes = []
    },

    [SET_LOADED] (state, value) {
      state.loaded = value
    }
  },

  actions: {
    resetStore ({ state, commit }) {
      state.unsubscribes.forEach(item => item())
      commit(REMOVE_UNSUBSCRIBES)
      commit(SET_WEBSITE, null)
      commit(SET_LOADED, false)
    },

    async loadWebsite ({ state, commit, dispatch }, id) {
      try {
        if (!id) throw new Error('Website ID needed')
        commit(SET_ORDER, null)
        commit(SET_WEBSITE, null)
        commit(SET_LOADED, false)

        let website = null
        dispatch('dashboard/activity/heartbeat', null, { root: true })

        const unsubscribe = onSnapshot(doc(db, WEBSITES_COLL, id), async (snap) => {
          website = { id: snap.id, ...snap.data() }
          if (website.group === null || state.order !== null) {
            dispatch('updateWebsiteData', { website, order: state.order })
          } else if (website.group) {
            const orderId = website.group
            const unsubscribe = onSnapshot(doc(db, ORDERS_COLL, orderId), (snap) => {
              commit(SET_ORDER, { id: snap.id, ...snap.data() })
              dispatch('updateWebsiteData', { website, order: state.order })
            })
            commit(ADD_UNSUBSCRIBE, unsubscribe)
          }
        })
        commit(ADD_UNSUBSCRIBE, unsubscribe)
      } catch {
        commit(SET_ORDER, null)
        commit(SET_WEBSITE, null)
        commit(SET_LOADED, true)
      }
    },

    updateWebsiteData ({ state, commit, dispatch }, { website, order }) {
      const result = populateWebsiteWithOrderFields(website, order)

      // Add local fields
      if (state.website?.builder) {
        result.builder = {
          ...state.website.builder,
          publishing: result.builder.publishing
        }
      }

      dispatch('builder/preview/update', result, { root: true })

      commit(SET_WEBSITE, result)
      const loaded = website !== null
        ? website.group === null || order !== null
        : false
      commit(SET_LOADED, loaded)
    },

    addWidgets ({ state, dispatch, rootGetters }, libWidgets) {
      const widgets = []
      for (const widgetTemplate of libWidgets) {
        // Generate default items for lists
        ADD_DEFAULT_ITEMS_FOR_WIDGET(widgetTemplate)

        const widget = CREATE_WIDGET(
          widgetTemplate,
          rootGetters['builder/toolbar/selectedPage']?.id ?? null,
          state.website.widgets.length)

        widgets.push(widget)
      }
      dispatch('update', {
        id: state.website.id,
        data: { widgets: [...state.website.widgets, ...widgets] }
      })
    },

    replaceWidget ({ state, dispatch }, widget) {
      const index = state.website.widgets
        .findIndex(item => item.id === widget.id)
      state.website.widgets[index] = widget
      dispatch('update', {
        id: state.website.id,
        data: { widgets: state.website.widgets }
      })
    },

    updateWidget ({ state, dispatch }, { widget, data }) {
      const { website } = UPDATE_WIDGET(state.website, widget, data)
      if (website) {
        dispatch('update', {
          id: state.website.id,
          data: { widgets: website.widgets }
        })
      }
    },

    removeWidget ({ state, dispatch }, widget) {
      const { removed, website } = REMOVE_WIDGET(state.website, widget)
      if (removed) {
        dispatch('update', {
          id: state.website.id,
          data: { widgets: website.widgets }
        })
        return removed
      }
      return null
    },

    updatePage ({ state, dispatch }, page) {
      const index = state.website.pages
        .findIndex(item => item.id === page.id)
      if (index !== -1) {
        state.website.pages[index] = page
        return dispatch('update', {
          id: state.website.id,
          data: { pages: state.website.pages }
        })
      }
      console.warn('updatePage: Page not found!')
    },

    update ({ state, commit, dispatch }, { id, data }) {
      // SAVE CHANGES
      dispatch('builder/history/capture', { data, hash: state.website.hash }, { root: true })

      // UPDATE BUILDER PREVIEW
      dispatch('builder/preview/update', data, { root: true })

      id && dispatch('dashboard/activity/heartbeat', null, { root: true })

      // UPDATE IN VUEX
      const { builder, ...payload } = data
      if (builder) {
        commit(SET_WEBSITE, {
          ...state.website,
          builder // Updates builder field localy only
        })
      }

      // UPDATE IN FIRESTORE
      if (!isObjectEmpty(payload)) {
        payload.hash = generateId(30)
        return setDoc(doc(db, WEBSITES_COLL, id), payload, { merge: true })
      }
      return null
    },

    forceWebsiteUpdate ({ state }, data) {
      return setDoc(doc(db, WEBSITES_COLL, state.website.id), data, { merge: true })
    },

    // Used for upload from JSON file
    rewriteWebsite (_, data) {
      return setDoc(doc(db, WEBSITES_COLL, data.id), data)
    },

    saveAsPreset ({ state }, { name }) {
      return api.websites.saveAsPreset(state.website?.id, { name })
    },

    makeSnapshot ({ state, dispatch }) {
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      return api.websites.makeSnapshot(state.website?.id)
    },

    async createByPreset ({ dispatch, rootGetters }, preset) {
      const selectedOrder = rootGetters['orders/selectedOrder']
      if (!selectedOrder) {
        console.warn('Order need to be selected!')
        return
      }
      if (!preset) {
        console.warn('Preset need to be selected!')
        return
      }
      const { data, success } = await api.websites.createByPreset({
        presetId: preset.id,
        orderId: selectedOrder.id
      })
      if (!success) {
        console.warn('Website not created!')
        return
      }
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      dispatch('loadWebsite', data.id)
      return { data, success }
    },

    async deleteWebsite ({ dispatch }, website) {
      // delete a snapshot
      const snapshotRef = doc(db, SNAPSHOTS_COLL, website.id)
      const snapshotSnap = await getDoc(snapshotRef)
      if (snapshotSnap.exists()) {
        await deleteDoc(snapshotRef)
      }

      // delete a snapshot resources
      await dispatch('storage/deleteFiles', `${SNAPSHOTS_COLL}/${website.id}`, { root: true })

      // delete a website
      const websiteRef = doc(db, WEBSITES_COLL, website.id)
      const websiteSnap = await getDoc(websiteRef)
      if (websiteSnap.exists()) {
        await deleteDoc(websiteRef)
      }

      // delete a website resources
      await dispatch('storage/deleteFiles', `${WEBSITES_COLL}/${website.id}`, { root: true })

      // update an order
      return dispatch('orders/update', { id: website.group, data: { website: { id: null } } }, { root: true })
    }
  }
}
