<template>
  <transition name="fade">
    <div
      v-if="screenshot.url"
      class="clipboard-preview"
    >
      <div
        class="screenshot"
        :style="{
          height: `${screenshot.height}px`,
          width: `${screenshot.width}px`,
          backgroundImage: `url(${screenshot.url})`
        }"
      />
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import { copyImageToClipboard } from '../utils'

export default {
  data: () => ({
    screenshot: {
      url: null,
      height: null,
      width: null
    }
  }),

  mounted () {
    Vue.prototype.$clipboard = {
      open: (url, height, width) => {
        this.screenshot.url = url
        this.screenshot.height = height * 0.8
        this.screenshot.width = width * 0.8
        return copyImageToClipboard(url, () => {
          setTimeout(() => {
            this.screenshot.url = null
            this.screenshot.height = null
            this.screenshot.width = null
          }, 3000)
        })
      }
    }
  }
}
</script>

<style scoped>
.clipboard-preview {
  position: fixed;
  right: 18px;
  bottom: 18px;
  padding: 6px;
  border: 4px solid #000;
  background-color: var(--color-surface);
  z-index: 100;
  border-radius: 14px;
  filter: drop-shadow(rgba(0, 0, 0, 0.208) 0px 8px 16px);
}
.screenshot {
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
