<template lang="pug">
.toolbar__item.toolbar__item--visible-action
  .toolbar__item-title {{ value || name }}
  .toolbar__item-action
    v-menu(
      left
      offset-y
      :min-width="280"
      :close-on-content-click="false")
      template(v-slot:activator="{ on, attrs }")
        button.btn(
          v-bind="attrs"
          v-on="on")
          v-icon(small) mdi-pencil
      v-textarea.text-input(
        solo
        auto-grow
        height="80"
        :label="name"
        :value="value"
        :hide-details="true"
        @change="onUpdate($event)")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    }
  },

  methods: {
    onUpdate (text) {
      this.$emit('update', text)
    }
  }
}
</script>

<style scoped>
.toolbar__item-title {
  width: calc(100% - 40px);
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

<style>
.text-input {
  font-size: 14px;
}

.text-input textarea {
  line-height: 1.5;
}
</style>
