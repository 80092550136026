export default [
  {
    path: 'settings',
    redirect: 'settings/presets',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'presets',
        component: () => import('../views/presets/Index.vue')
      },
      {
        path: 'widgets',
        component: () => import('../views/widgets/Index.vue')
      }
    ]
  }
]
