import {
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  collection,
  getFirestore
} from 'firebase/firestore'
import { deepClone } from '@/util/utils.js'
import { ADD_DEFAULT_ITEMS_FOR_WIDGET } from '@/modules/builder/utils'

const db = getFirestore()

const COLLECTION = 'widgets'

const SET_ITEMS = 'SET_ITEMS'
const ADD_ITEM = 'ADD_ITEM'
const UPDATE_ITEM = 'UPDATE_ITEM'
const REMOVE_ITEM = 'REMOVE_ITEM'

export default {
  namespaced: true,

  state: {
    items: []
  },

  getters: {
    items: (state) => state.items
  },

  mutations: {
    [SET_ITEMS] (state, value) {
      state.items = value
    },

    [ADD_ITEM] (state, value) {
      state.items = [...state.items, value]
    },

    [UPDATE_ITEM] (state, value) {
      state.items = state.items
        .map(item => {
          return item.id === value.id
            ? value
            : item
        })
        .sort((a, b) => a.order - b.order)
    },

    [REMOVE_ITEM] (state, id) {
      state.items = state.items.filter(item => item.id !== id)
    }
  },

  actions: {
    async load ({ commit }) {
      const docs = await getDocs(collection(db, COLLECTION))
      const items = []
      docs.forEach((doc) => {
        items.push({
          id: doc.id,
          ...doc.data()
        })
      })
      commit(SET_ITEMS, items.sort((a, b) => a.order - b.order))
    },

    async getById (_, id) {
      const docSnap = await getDoc(doc(db, COLLECTION, id))
      if (docSnap.exists()) {
        return docSnap.data()
      }
      return null
    },

    async create ({ commit, state }, props) {
      const data = {
        ...props,
        name: 'New widget',
        type: 'regular',
        component: null,
        order: state.items.length,
        config: {},
        enabled: false
      }
      const snap = await addDoc(collection(db, COLLECTION), data)
      commit(ADD_ITEM, { id: snap.id, ...data })
    },

    update ({ commit }, data) {
      commit(UPDATE_ITEM, data)
      const { id, ...props } = data
      return setDoc(doc(db, COLLECTION, id), props)
    },

    delete ({ commit }, id) {
      commit(REMOVE_ITEM, id)
      return deleteDoc(doc(db, COLLECTION, id))
    },

    async upgrade ({ dispatch }, widget) {
      const result = deepClone(widget)
      const parent = await dispatch('getById', widget.fatherId)
      if (parent) {
        result.name = parent.name
        result.icon = parent.icon
        for (const key in parent.config) {
          if (!widget.config[key]) {
            result.config[key] = parent.config[key]
          }
        }
      }
      return result
    },

    async reset ({ dispatch }, widget) {
      const result = deepClone(widget)
      const parent = await dispatch('getById', widget.fatherId)
      if (parent) {
        result.name = parent.name
        result.config = parent.config
        ADD_DEFAULT_ITEMS_FOR_WIDGET(result)
      }
      return result
    }
  }
}
