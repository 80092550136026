import {
  query,
  where,
  collection,
  getFirestore,
  getDocs
} from 'firebase/firestore'

import { capitalizeFirstLetter } from '@/util/utils.js'

const db = getFirestore()

const WEBSITES = 'websites' // we storing draft items in website collection
const SNAPSHOTS = 'snapshots' // presets lib items stored in snapshots collection

const SET_DRAFT_ITEMS = 'SET_DRAFT_ITEMS'
const SET_ITEMS = 'SET_ITEMS'

const SET_SELECTED_PRESET = 'SET_SELECTED_PRESET'

export default {
  namespaced: true,

  state: {
    items: [], // items used for new website creation
    draftItems: [], // items used for editing in a builder
    selectedPreset: null
  },

  getters: {
    items: (state) => state.items,
    designs: (state) => [...new Set(state.items.map(item => item.design))]
      .map(key => ({
        key,
        name: capitalizeFirstLetter(key)
      })),
    draftItems: (state) => state.draftItems,
    selectedPreset: (state) => state.selectedPreset
  },

  mutations: {
    [SET_ITEMS] (state, value) {
      state.items = value
    },

    [SET_DRAFT_ITEMS] (state, value) {
      state.draftItems = value
    },

    [SET_SELECTED_PRESET] (state, value) {
      state.selectedPreset = value
    }
  },

  actions: {
    // Presets used for editing and presets table
    async loadDraftItems ({ commit }) {
      const docs = await getDocs(query(collection(db, WEBSITES), where('group', '==', null)))
      const items = []
      docs.forEach((doc) => {
        const website = doc.data()
        items.push({
          id: doc.id,
          ...website.meta,
          design: website.general.design,
          version: website.general.template
        })
      })
      commit(SET_DRAFT_ITEMS, items
        .sort((a, b) => a.version !== b.version
          ? a.version.localeCompare(b.version)
          : a.package !== b.package
            ? b.package.localeCompare(a.package)
            : a.design.localeCompare(b.design)))
    },

    // Presets used for website creation
    async loadItems ({ commit }) {
      const docs = await getDocs(query(collection(db, SNAPSHOTS), where('group', '==', null)))
      const items = []
      docs.forEach((doc) => {
        const website = doc.data()
        if (website.meta.isPreset) {
          items.push({
            id: doc.id,
            ...website.meta,
            design: website.general.design,
            version: website.general.template
          })
        }
      })
      commit(SET_ITEMS, items)
    },

    select ({ commit }, preset) {
      commit(SET_SELECTED_PRESET, preset)
    }
  }
}
