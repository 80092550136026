import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import orders from '@/modules/orders/router'
import dashboard from '@/modules/dashboard/router'
import builder from '@/modules/builder/router'
import settings from '@/modules/settings/router'
import finances from '@/modules/finances/router'
import development from '@/modules/development/router'

Vue.use(VueRouter)

const lazy = (path) => () => import(`@/views/${path}/Index.vue`)

const routes = [
  {
    path: '/login',
    component: lazy('auth')
  },
  {
    path: '/app',
    redirect: '/app/orders',
    component: lazy('app'),
    children: [
      ...dashboard,
      ...orders,
      ...builder,
      ...settings,
      ...finances,
      ...development
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const ADMIN_ROUTES = ['table', 'settings', 'finances', 'calendar']
  if (!store.getters['auth/isAdmin'] &&
    ADMIN_ROUTES.some(path => to.path.includes(path))) {
    console.log('not allowed')
    return false
  }
  next()
})

export default router
