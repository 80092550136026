import {
  doc,
  query,
  where,
  collection,
  orderBy,
  onSnapshot,
  addDoc,
  setDoc,
  getDoc,
  getFirestore,
  deleteDoc,
  getCountFromServer
} from 'firebase/firestore'

import { TEMPLATE_VERSIONS } from '@/util/consts'
import { formatOrderNumber } from '@/util/utils.js'

const db = getFirestore()
const COLLECTION = 'orders'

const SET_ORDERS = 'SET_ORDERS'
const SET_SANDBOX_MODE = 'SET_SANDBOX_MODE'
const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER'
const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT'
const SET_LOADED = 'SET_LOADED'
const SET_UNSUBSCRIBE = 'SET_UNSUBSCRIBE'

export default {
  namespaced: true,

  state: {
    items: [],
    sandboxMode: false,
    unsubscribe: null,
    selectedOrder: null,
    selectedAccount: null,
    loaded: false
  },

  getters: {
    items: (state) => state.items
      .filter(item => item.sandbox === state.sandboxMode)
      .map(item => ({ ...item, formattedNumber: formatOrderNumber(item.number) })),
    sandboxMode: (state) => state.sandboxMode,
    selectedOrder: (state) => state.selectedOrder,
    selectedAccount: (state) => state.selectedAccount,
    hasWebsite: (state) => !!state.selectedOrder?.website,
    loaded: (state) => state.loaded
  },

  mutations: {
    [SET_ORDERS] (state, data) {
      state.items = data
    },

    [SET_SANDBOX_MODE] (state, data) {
      state.sandboxMode = data
    },

    [SET_UNSUBSCRIBE] (state, data) {
      state.unsubscribe = data
    },

    [SET_SELECTED_ORDER] (state, data) {
      state.selectedOrder = data
    },

    [SET_SELECTED_ACCOUNT] (state, data) {
      state.selectedAccount = data
    },

    [SET_LOADED] (state, data) {
      state.loaded = data
    }
  },

  actions: {
    load ({ state, commit, dispatch }) {
      if (!state.unsubscribe) {
        const q = query(collection(db, COLLECTION), orderBy('number', 'desc'))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = []
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.id,
              ...doc.data()
            }
            items.push(data)
          })
          commit(SET_ORDERS, items)
        })
        commit(SET_UNSUBSCRIBE, unsubscribe)
        dispatch('dashboard/activity/heartbeat', null, { root: true })
      }
    },

    async mapOrders ({ state, dispatch }) {
      console.log('Started!')
      const items = state.items.filter(item => !item.sandbox && item.website?.id && item.location.lat === undefined)
      let index = 0
      for (const order of items) {
        const websiteDoc = await getDoc(doc(db, 'websites', order.website.id))
        const websiteData = {
          id: websiteDoc.id,
          ...websiteDoc.data()
        }
        let address = null

        if (websiteData.general.template === '1.4') {
          const component = websiteData.widgets
            .find(widget => widget.component === 'w-address' && widget.enabled)
          if (component) {
            const lang = websiteData.languages[0]
            const { value } = component.config.items.subConfigs[0].content
            address = value[lang]
          }
        } else {
          const component = websiteData.widgets
            .find(widget => widget.component === 'address-info' && widget.enabled)
          if (component) {
            const lang = websiteData.languages[0]
            const { value } = component.config['address-items'].subConfigs[0].text
            address = value[lang]
          }
        }
        console.log(index++, order.id, websiteData.id, websiteData.general.template)
        if (address) {
          address = address.toLowerCase()
          if (address.indexOf('початок') !== -1) {
            address = address.substring(0, address.indexOf('початок'))
          }
          console.log(address)
          const resp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCeQJlEjvwPc2WORng91zQ51z07DSWPtoA`)
          const data = await resp.json()
          if (data.status === 'OK') {
            const { location } = data.results[0].geometry
            await dispatch('update', { id: order.id, data: { location } })
          } else {
            console.log('Skipped!')
          }
        }
      }
      console.log('Done =)')
    },

    async loadById ({ commit, dispatch }, id = null) {
      commit(SET_LOADED, false)
      const orderDoc = await getDoc(doc(db, COLLECTION, id))
      const order = {
        id: orderDoc.id,
        ...orderDoc.data()
      }
      commit(SET_SELECTED_ORDER, order)
      commit(SET_LOADED, true)
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      return order
    },

    async create ({ state, dispatch }, { data }) {
      const coll = collection(db, COLLECTION)
      const q = query(coll, where('sandbox', '==', state.sandboxMode))
      const snapshot = await getCountFromServer(q)
      const number = snapshot.data().count + 1
      const template = TEMPLATE_VERSIONS[0]
      const payload = {
        ...data,
        website: {
          ...data.website,
          template
        },
        number,
        sandbox: state.sandboxMode,
        createdAt: new Date().toISOString()
      }
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      return addDoc(collection(db, COLLECTION), payload)
    },

    async exists (_, { field, value }) {
      const coll = collection(db, COLLECTION)
      const q = query(coll, where(field, '==', value))
      const snapshot = await getCountFromServer(q)
      return snapshot.data().count > 0
    },

    update ({ dispatch }, { id, data }) {
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      return setDoc(doc(db, COLLECTION, id), data, { merge: true })
    },

    delete (_, id = null) {
      return deleteDoc(doc(db, COLLECTION, id))
    }
  }
}
