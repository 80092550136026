<template>
  <div
    class="property"
    :class="{ 'property--list': property.type === 'LIST' }"
  >
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-icon
          small
          class="mr-2 cursor-move"
        >
          mdi-drag-horizontal-variant
        </v-icon>
        <div class="mr-2">{{ property.name }}</div>
      </div>
      <div class="d-flex align-center">
        <v-chip
          dark
          label
          x-small
          class="mr-1"
          :color="colors[property.type]"
        >
          {{ property.type }}
        </v-chip>
        <PropertiesListItemDetails
          :property="property"
          :is-sub="isSub"
          @remove="onPropertyRemove"
          @update="onPropertyUpdate" />
      </div>
    </div>

    <!-- SUB CONFIG TEMPLATE -->
    <PropertiesList
      v-if="property.type === 'LIST'"
      class="mt-2"
      :is-sub="true"
      :id="id"
      :config="property.template"
      @update="onSubconfigUpdate" />
  </div>
</template>

<script>
import PropertiesListItemDetails from './PropertiesListItemDetails.vue'

export default {
  props: {
    id: String,
    property: Object,
    isSub: Boolean
  },

  components: {
    PropertiesListItemDetails
  },

  data: () => ({
    colors: {
      COLOR: 'teal',
      TEXT: 'blue-grey',
      LIST: 'deep-purple',
      SELECT: 'green'
    }
  }),

  methods: {
    onPropertyUpdate (data) {
      this.$emit('update', data)
    },

    onPropertyRemove (data) {
      this.$emit('remove', data)
    },

    onSubconfigUpdate (config) {
      const property = {
        ...this.property,
        template: config
      }
      this.$emit('update', property)
    }
  }
}
</script>

<style lang="scss">
.property {
  padding: 4px 8px;
  border-radius: var(--border-radius);
  background-color: var(--color-surface);
  border: 1px solid var(--color-border);
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--color-primary);
    box-shadow: 0px 0px 2px var(--color-primary);
  }

  &--list {
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: var(--color-background);
  }
}
</style>
