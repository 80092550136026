const SET_USER = 'SET_USER'
const SET_LOGGED_IN = 'SET_LOGGED_IN'

export default {
  namespaced: true,

  state: {
    isAppInited: false,
    user: {
      loggedIn: false,
      data: null
    }
  },

  getters: {
    user (state) {
      return state.user
    },

    isAdmin (state) {
      return state.user.data?.admin
    },

    isAppInited: (state) => state.isAppInited
  },

  mutations: {
    [SET_LOGGED_IN] (state, value) {
      state.user.loggedIn = value
    },

    [SET_USER] (state, data) {
      state.user.data = data
      state.isAppInited = true
    }
  },

  actions: {
    initUser ({ commit }, user = null) {
      commit(SET_LOGGED_IN, user !== null)
      if (user) {
        commit(SET_USER, user)
      } else {
        commit(SET_USER, null)
      }
    }
  }
}
