import api from '@/api'

export default {
  namespaced: true,

  actions: {
    publish ({ dispatch }, website) {
      if (website &&
        website.firebaseHosting &&
        !website.builder?.publishing) {
        api.github.executeWorkflow(website.id, {
          ...website.deploy,
          ...website.firebaseHosting,
          subdomain: website.general.subdomain,
          template: website.general.template
        })
        const orderData = {
          status: 'preview',
          website: {
            publishing: true,
            publishedAt: new Date()
              .toISOString()
              .substring(0, 19)
              .replace('T', ' ')
          }
        }
        dispatch('dashboard/activity/heartbeat', null, { root: true })
        dispatch('orders/update', { id: website.group, data: orderData }, { root: true })
      }
    }
  }
}
