const ADD_ROUTE = 'ADD_ROUTE'

export default {
  namespaced: true,

  state () {
    return {
      navigation: []
    }
  },

  getters: {
    navigation: (state) => state.navigation
  },

  mutations: {
    [ADD_ROUTE] (state, item) {
      state.navigation = [...state.navigation, item]
    }
  },

  actions: {
    registerAppNav ({ commit }, data) {
      commit(ADD_ROUTE, data)
    }
  }
}
