<template>
  <div class="toolbar__item toolbar__item--visible-action">
    <div class="toolbar__item-title">
      <span>{{ name || 'Image'}}</span>
    </div>
    <div class="toolbar__item-action">
      <div class="control-wrapper">
        <v-img
          v-if="value"
          class="image-cover mr-1"
          height="20"
          maxWidth="20"
          style="margin-left: 4px"
          :src="imageThumbnail" />

        <button
          v-if="allowDelete"
          class="btn"
          @click="deleteImage"
        >
          <v-icon
            small
            color="error"
          >
            mdi-delete
          </v-icon>
        </button>
        <button
          v-else
          class="btn"
          :style="{ display: value === null ? 'block' : null }"
          @click="$images.open(update)"
        >
          <v-icon small>
            mdi-upload
          </v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    configSelectedLang: {
      type: String,
      default: 'uk'
    },
    bindValueToLanguage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('builder/website', [
      'website'
    ]),

    selectedLang () {
      return this.website
        ? this.website.builder.selectedLang
        : this.configSelectedLang
    },

    imageThumbnail () {
      if (!this.value) {
        return null
      }
      const fallback = Object
        .keys(this.value)
        .find(key => this.value[key])
      return this.bindValueToLanguage
        ? this.value[this.selectedLang]
          ? this.value[this.selectedLang].thumbnail
          : this.value[fallback].thumbnail
        : this.value.thumbnail
    },

    allowDelete () {
      if (!this.value ||
        this.value.locked ||
        this.bindValueToLanguage) {
        return false
      }
      return true
    }
  },

  methods: {
    update (data) {
      if (this.bindValueToLanguage) {
        const value = this.value || {}
        value.locked = false
        value[this.selectedLang] = data
        this.$emit('update', value)
      } else {
        this.$emit('update', data)
      }
    },

    deleteImage () {
      this.bindValueToLanguage
        ? this.$images.delete(this.value[this.selectedLang])
        : this.$images.delete(this.value)
      this.update(null)
    }
  }
}
</script>

<style lang="scss">
.control-wrapper {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 1px;

  &:not(:hover) .btn {
    display: none;
  }

  &:hover .image-cover {
    display: none;
  }
}
</style>
