import orders from './store'

const NAVIGATION = {
  label: 'Orders',
  path: 'orders',
  adminOnly: false
}

export default {
  install (_, store) {
    store.registerModule('orders', orders)
    store.dispatch('app/registerAppNav', NAVIGATION)
  }
}
