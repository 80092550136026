import { render, staticRenderFns } from "./TextConfigItem.vue?vue&type=template&id=71cb39b0&scoped=true&lang=pug"
import script from "./TextConfigItem.vue?vue&type=script&lang=js"
export * from "./TextConfigItem.vue?vue&type=script&lang=js"
import style0 from "./TextConfigItem.vue?vue&type=style&index=0&id=71cb39b0&prod&scoped=true&lang=css"
import style1 from "./TextConfigItem.vue?vue&type=style&index=1&id=71cb39b0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cb39b0",
  null
  
)

export default component.exports