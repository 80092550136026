export const open = (accept = '*', callback = null) => {
  const el = document.createElement('input')
  el.setAttribute('type', 'file')
  el.setAttribute('accept', accept)
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.click()
  el.onchange = (e) => {
    if (e.target.files.length) {
      callback && callback(e.target.files[0])
    }
    document.body.removeChild(el)
  }
}

export const openJson = (callback) => {
  open('.json,application/json', (file) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const json = JSON.parse(event.target.result)
      callback && callback(json)
    }
    reader.readAsText(file)
  })
}

export const extractFilePath = (src) => decodeURIComponent(src.slice(src.indexOf('/o/') + 3).split('?')[0])
