<template>
  <ColorPickerRange
    :min="0"
    :max="1"
    :color="alphaColor"
    :background="alphaBackground"
    :value="value.a"
    @input="onUpdate"
  />
</template>

<script>
import { RGBAtoCSS } from './utils'
import ColorPickerRange from './ColorPickerRange.vue'

export default {
  components: {
    ColorPickerRange
  },

  props: {
    value: Object
  },

  computed: {
    alphaColor () {
      return RGBAtoCSS(this.value)
    },

    alphaBackground () {
      return [
        RGBAtoCSS(this.value, 0),
        RGBAtoCSS(this.value, 1)
      ]
    }
  },

  methods: {
    onUpdate (a) {
      this.$emit('input', { ...this.value, a })
    }
  }
}
</script>
