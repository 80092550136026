<template lang="pug">
.toolbar__item.toolbar__item--visible-action
  .toolbar__item-title {{ value }}
  .toolbar__item-action
    v-menu(
      left
      offset-y
      :close-on-content-click="false")
      template(v-slot:activator="{ on, attrs }")
        button.btn(
          v-bind="attrs"
          v-on="on")
          v-icon(small) mdi-pencil
      v-time-picker(
        format="24hr"
        :value="value"
        @input="onInput"
        @click:minute="onChange")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },

  data: () => ({
    time: null
  }),

  methods: {
    onInput ($event) {
      this.time = $event
    },

    onChange () {
      this.$emit('update', this.time)
    }
  }
}
</script>
