import builder from './store'
import { APP_HOST } from '@/util/config'

import ConfigItems from './components/panel/widgets/config-items/ConfigItems'
import TGroup from './components/toolbar/t-group/TGroup.vue'
import TToggler from './components/toolbar/t-toggler/TToggler.vue'
import TTabs from './components/toolbar/t-tabs/TTabs.vue'

export default {
  install (Vue, store) {
    Vue.prototype.$templateUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080/template'
        : `https://${APP_HOST}/template`

    Vue.component('ConfigItems', ConfigItems)
    Vue.component('TGroup', TGroup)
    Vue.component('TToggler', TToggler)
    Vue.component('TTabs', TTabs)

    store.registerModule('builder', builder)
  }
}
