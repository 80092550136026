<template>
  <v-menu
    ref="menu"
    absolute
    left
    offset-y
    :nudge-top="-14"
    :nudge-width="380"
    :max-width="380"
    :close-on-content-click="false"
    v-model="activated"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-settings</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-text-field
          label="Name"
          class="mb-3"
          outlined
          dense
          hide-details
          v-model="form.name"
        />
        <v-text-field
          label="Key"
          class="mb-3"
          outlined
          dense
          hide-details
          v-model="form.key"
        />
        <v-select
          class="mb-3"
          label="Type"
          :items="types"
          outlined
          dense
          hide-details
          v-model="form.type"
        />

        <v-text-field
          v-if="hasOptions(form.type)"
          v-model="form.options"
          label="Options"
          class="mb-3"
          outlined
          dense
          hide-details
        />

        <template v-if="hasValue(form.type)">
          <PropertyTextValueEdit
            v-if="useTextarea(form.type)"
            v-model="value"
          />
          <v-text-field
            v-else
            v-model="value"
            label="Value"
            class="mb-3"
            outlined
            dense
            hide-details
          />
        </template>

        <template v-else>
          <ImageConfigItem
            v-if="property.type === 'IMAGE'"
            :item="property"
            @update="onValueUpdate"
          />
        </template>

        <template v-if="isList(property.type)">
          <PropertyLangSelect
            v-if="isBindedToLanguage(property)"
            v-model="selectedLang"
          />
          <ListConfigItem
            class="toolbar toolbar--dense"
            :item="property"
            :configSelectedLang="selectedLang"
            @sub-update="onListItemsUpdate"
          />
        </template>

        <v-switch
          v-if="hasValue(form.type)"
          v-model="form.bindValueToLanguage"
          inset
          hide-details
          label="Bind value to language"
          class="v-switch--reverse mb-4"
        />
        <v-switch
          v-if="!isSub"
          v-model="form.enabled"
          inset
          hide-details
          label="Enabled"
          class="v-switch--reverse mb-4"
        />
        <v-btn
          dark
          block
          depressed
          color="primary"
          @click="onUpdate"
        >
          Update
        </v-btn>
        <v-btn
          block
          depressed
          class="mt-2"
          @click="onRemove"
        >
          Remove
        </v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import PropertyLangSelect from './PropertyLangSelect.vue'
import PropertyTextValueEdit from './PropertyTextValueEdit.vue'

import ImageConfigItem from '../../../builder/components/panel/widgets/config-items/ImageConfigItem.vue'
import ListConfigItem from '../../../builder/components/panel/widgets/config-items/ListConfigItem.vue'

export default {
  props: {
    isSub: Boolean,
    property: {
      type: Object
    }
  },

  components: {
    ImageConfigItem,
    ListConfigItem,
    PropertyLangSelect,
    PropertyTextValueEdit
  },

  mounted () {
    Object
      .keys(this.property)
      .forEach(key => {
        this.form[key] = this.property[key]
      })
  },

  data: () => ({
    activated: false,
    types: [
      'UID',
      'COLOR',
      'TEXT',
      'STRING',
      'LINK',
      'LIST',
      'IMAGE',
      'FILE',
      'DATE',
      'TIME',
      'SELECT',
      'SLIDER',
      'BOOL'
    ],
    selectedLang: 'uk',
    form: {
      name: null,
      key: null,
      value: null,
      type: null,
      bindValueToLanguage: false,
      enabled: true
    }
  }),

  computed: {
    value: {
      get () {
        return this.form.value !== null && typeof this.form.value === 'object'
          ? JSON.stringify(this.form.value)
          : this.form.value
      },
      set (value) {
        try {
          this.form.value = value
            ? JSON.parse(value)
            : null
        } catch {
          this.form.value = value
        }
      }
    }
  },

  methods: {
    isList (type) {
      return type === 'LIST'
    },

    isBindedToLanguage (property) {
      return Object
        .keys(property.template)
        .map(key => property.template[key])
        .some(item => item.bindValueToLanguage)
    },

    hasOptions (type) {
      return ['SELECT'].includes(type)
    },

    useTextarea (type) {
      return ['TEXT', 'LINK'].includes(type)
    },

    hasValue (type) {
      return !['LIST', 'UID', 'IMAGE'].includes(type)
    },

    onRemove () {
      this.$emit('remove', this.form.key)
      this.activated = false
    },

    onUpdate () {
      const isList = this.isList(this.form.type)
      const result = {
        ...(isList ? { subConfigs: [] } : {}),
        ...this.form
      }
      this.$emit('update', result)
      this.activated = false
    },

    onListItemsUpdate (value) {
      this.$emit('update', value)
    },

    onValueUpdate (value) {
      this.form.value = value
      this.$emit('update', this.form)
    }
  }
}
</script>
