import { DOMAIN } from '@/util/config'

export const execute = async ({ method = 'GET', subdomain = 'api', url = null, body = null, headers = {} }) => {
  const response = await fetch(`https://${subdomain}.${DOMAIN}/api/${url}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...(body && { body: JSON.stringify(body) })
  })
  return response.json()
}
