<template>
  <v-menu
    left
    offset-y
    close-on-click
    :close-on-content-click="false"
    v-model="activated"
  >
    <template v-slot:activator="props">
      <slot
        name="activator"
        v-bind="props" />
    </template>
    <v-card elevation="0">
      <v-card-text class="v-dropdown__content">
        <slot />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    activated: false
  }),

  methods: {
    toggle () {
      this.activated = !this.activated
    }
  },

  watch: {
    activated (value) {
      if (value) {
        this.$emit('activated')
      }
    }
  }
}
</script>

<style lang="scss">
.v-dropdown__content {
  .v-divider {
    max-width: none !important;
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
}
</style>
