<template lang="pug">
.toolbar__item.toolbar__item--visible-action
  .toolbar__item-title {{ useVariants(value[selectedLang], 0) || name }}
  .toolbar__item-action
    v-menu(
      left
      offset-y
      :min-width="280"
      :close-on-content-click="false")
      template(v-slot:activator="{ on, attrs }")
        button.btn(
          v-bind="attrs"
          v-on="on")
          v-icon(small) mdi-pencil
      template(
        v-for="(lang, i) in languages"
        v-if="lang === selectedLang")
        v-textarea(
          solo
          auto-grow
          class="text-input"
          :label="`${name} ${lang}`"
          :value="value[lang]"
          :hide-details="true"
          @change="onUpdate($event, lang)")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    },
    configSelectedLang: {
      type: String,
      default: 'uk'
    }
  },

  computed: {
    ...mapGetters('builder/website', [
      'website'
    ]),

    languages () {
      return this.website
        ? this.website.languages
        : ['uk', 'en', 'ru']
    },

    selectedLang () {
      return this.website
        ? this.website.builder.selectedLang
        : this.configSelectedLang
    }
  },

  methods: {
    onUpdate (text, lang) {
      this.value[lang] = text
      this.$emit('update', this.value)
    },

    useVariants (input, index) {
      if (input && index !== -1) {
        let result = input
        const groups = [...input.matchAll(/{([^}]*)}/gm)]
        groups.forEach((item) => {
          const variants = item[1].split('|')
          const variantIndex = Math.min(index, variants.length - 1)
          result = result.replace(item[0], variants[variantIndex])
        })
        return this.removeHtmlTags(result)
      }
      return input
    },

    removeHtmlTags (str) {
      return str.replaceAll(/<\/?[^>]+(>|$)/gi, '')
    }
  }
}
</script>

<style scoped>
.toolbar__item-title {
  width: calc(100% - 40px);
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

<style>
.text-input {
  font-size: 14px;
}

.text-input textarea {
  line-height: 1.5;
}
</style>
