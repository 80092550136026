export const TAGS = [
  {
    type: 'inc',
    key: 'sale',
    name: 'Sale',
    color: '#52b788'
  },
  {
    type: 'inc',
    key: 'investment',
    name: 'Investment',
    color: '#52b788'
  },
  {
    type: 'exp',
    key: 'marketing',
    name: 'Marketing',
    color: '#7678ed'
  },
  {
    type: 'exp',
    key: 'seo',
    name: 'SEO',
    color: '#7678ed'
  },
  {
    type: 'exp',
    key: 'development',
    name: 'Development',
    color: '#f9c74f'
  },
  {
    type: 'incexp',
    key: 'equipment',
    name: 'Equipment',
    color: '#ffbf69'
  },
  {
    type: 'exp',
    key: 'operating',
    name: 'Operating',
    color: '#53b3cb'
  },
  {
    type: 'exp',
    key: 'design',
    name: 'Design',
    color: '#0a9396'
  },
  {
    type: 'exp',
    key: 'advertisement',
    name: 'Advertisement',
    color: '#cdb4db'
  },
  {
    type: 'exp',
    key: 'writing',
    name: 'Writing',
    color: '#ffafcc'
  }
]
