export default [
  {
    path: 'orders',
    redirect: 'orders/board',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'board',
        component: () => import('../views/board/Index.vue')
      },
      {
        path: 'calendar',
        component: () => import('../views/calendar/Index.vue')
      },
      {
        path: 'chat',
        component: () => import('../views/chat/Index.vue')
      },
      {
        path: 'chart',
        component: () => import('../views/chart/Index.vue')
      },
      {
        path: 'map',
        component: () => import('../views/map/Index.vue')
      },
      {
        path: 'table',
        component: () => import('../views/table/Index.vue')
      },
      {
        path: 'list',
        component: () => import('../views/list/Index.vue')
      }
    ]
  }
]
