import filesModule from './files/index.js'
import dashboardModule from './dashboard/index.js'
import financesModule from './finances/index.js'
import imagesModule from './images/index.js'
import ordersModule from './orders/index.js'
import builderModule from './builder/index.js'
import settingsModule from './settings/index.js'
import developmentModule from './development/index.js'

const modules = [
  filesModule,
  imagesModule,
  builderModule,
  developmentModule,
  // APP NAVIGATION START
  ordersModule,
  financesModule,
  settingsModule,
  dashboardModule
  // APP NAVIGATION END
]

export const registerModules = (Vue, store, router) => {
  modules.forEach((module) => {
    module.install(Vue, store, router)
  })
}
