<template>
  <PinturaEditorModal
    v-if="modalVisible"
    v-bind="editorProps"
    class="pintura-editor"
    cropSelectPresetFilter="landscape"
    :src="modalSrc"
    :willRenderCanvas="willRenderCanvas"
    :cropSelectPresetOptions="cropSelectPresetOptions"
    @pintura:hide="modalVisible = false"
    @pintura:process="handleModalProcess($event)"
  />
</template>

<script>
/* eslint-disable */
import Vue from 'vue'

import { PinturaEditorModal } from 'vue-pintura/vue-2'

import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
} from 'pintura'

import {
  loadImage,
  loadImageAsDataURL,
  resizeAndCompress,
  encodeImageToBlurhash
} from '../utils/index'

import {
  IMAGE_QUALITY,
  IMAGE_BASE_SIZE
} from '../config'

import { generateId, urlToBlob } from '@/util/utils.js'

setPlugins(plugin_crop, plugin_finetune)

export default {
  name: 'PhotoEditor',

  components: {
    PinturaEditorModal
  },

  data: (vm) => ({
    editorProps: {
      imageReader: createDefaultImageReader(),
      imageWriter: createDefaultImageWriter(),
      shapePreprocessor: createDefaultShapePreprocessor(),
      ...plugin_finetune_defaults,
      ...markup_editor_defaults,
      locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...markup_editor_locale_en_gb,
      },
    },

    cropSelectPresetOptions: [
      [undefined, 'Custom'],
      [1, 'Square'],

      // shown when cropSelectPresetFilter is set to 'landscape'
      [2 / 1, '2:1'],
      [3 / 2, '3:2'],
      [4 / 3, '4:3'],
      [16 / 10, '16:10'],
      [16 / 9, '16:9'],

      // shown when cropSelectPresetFilter is set to 'portrait'
      [1 / 2, '1:2'],
      [2 / 3, '2:3'],
      [3 / 4, '3:4'],
      [10 / 16, '10:16'],
      [9 / 16, '9:16'],
    ],

    // Let's draw a circle on top of the editor preview when in the crop util
    willRenderCanvas: (shapes, state) => {
      const {
        utilVisibility,
        selectionRect,
        lineColor,
        backgroundColor
      } = state

      // Exit if crop utils is not visible
      if (utilVisibility.crop <= 0) return shapes

      // Get variable shortcuts to the crop selection rect
      const { x, y, width, height } = selectionRect

      // Exit if rect isn't square
      if (Math.abs(width - height) > 1) return shapes

      // Add circle shape as a crop mask
      return {
        // Copy all props from current shapes
        ...shapes,

        // Now we add an inverted ellipse shape to the interface shapes array
        interfaceShapes: [
          {
            x: x + width * 0.5,
            y: y + height * 0.5,
            rx: width * 0.5,
            ry: height * 0.5,
            opacity: utilVisibility.crop,
            inverted: true,
            backgroundColor: [...backgroundColor, 0.5],
            strokeWidth: 1,
            strokeColor: [...lineColor]
          },

          // Spread all existing interface shapes onto the array
          ...shapes.interfaceShapes
        ]
      }
    },

    // modal
    modalSrc: null,
    modalVisible: false,

    isProfilePicMode: true,

    doneCallback: null
  }),

  mounted () {
    Vue.prototype.$images = {
      open: (callback = null) => {
        this.$files.open('image/*', async (file) => {
          this.doneCallback = callback
          this.modalSrc = await loadImageAsDataURL(file)
          this.modalVisible = true
        })
      },
      delete: (image) => {
        if (image.locked) {
          return
        }
        image.src && this.$files.delete(image.src)
        image.thumbnail && this.$files.delete(image.thumbnail)
      }
    }
  },

  methods: {
    async handleModalProcess (res) {
      const url = URL.createObjectURL(res.dest)

      // Prepear for resize
      const blob = await urlToBlob(url)
      const img = await loadImage(url)
      const ratio = img.height / img.width
      const basefilename = generateId(12)

      // Resize images
      const image = await this.resizeAndUpload(blob, basefilename, ratio, 2)
      const thumbnail = await this.resizeAndUpload(blob, basefilename, ratio, 0.5)

      // Create BlurHash
      const resizedImage = await loadImage(URL.createObjectURL(image.file))
      const blurhash = await encodeImageToBlurhash(resizedImage)

      // Create doc object
      const data = {
        blurhash,
        name: basefilename,
        src: image.src,
        width: image.width,
        height: image.height,
        thumbnail: thumbnail.src
      }

      // Execute outside logic
      this.doneCallback && this.doneCallback(data)

      // Reset state
      this.doneCallback = null
      this.modalVisible = false
    },

    async resizeAndUpload (blob, basefilename, ratio, scale = 1, quality = IMAGE_QUALITY) {
      const width = IMAGE_BASE_SIZE * scale
      const height = Math.floor(IMAGE_BASE_SIZE * scale * ratio)
      const options = {
        quality,
        maxWidth: width,
        maxHeight: height
      }
      const file = await resizeAndCompress(blob, options)
      const scaleString = scale.toString().replace('.', '-')
      const name = `${basefilename}_x${scaleString}.jpeg`
      const { src } = await this.upload(file, name)
      return {
        src,
        file,
        width,
        height
      }
    },

    upload (file, name) {
      return new Promise((resolve, reject) => {
        const options = { name, group: this.$route.params.id }
        this.$files.upload(file, options, resolve)
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../../../local_modules/pintura/pintura.css';

.pintura-editor {
  --color-background: 0, 0, 0;
  --color-foreground: 255, 255, 255;
}
</style>
