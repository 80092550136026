<template lang="pug">
v-menu(
  ref="menu"
  v-model="showMenu"
  :left="left"
  :close-on-content-click="false"
  :return-value.sync="date"
  offset-y
  min-width="auto"
  transition="scale-transition")
  template(v-slot:activator="{ on, attrs }")
    v-text-field.mt-3(
      outlined
      dense
      hide-details
      v-model="date"
      :label="label"
      readonly
      v-bind="attrs"
      v-on="on")
  v-date-picker(
    v-model="date"
    no-title
    scrollable)
    v-spacer
    v-btn(
      text
      color="primary"
      @click="showMenu = false")
      | Cancel
    v-btn(
      text
      color="primary"
      @click="onSave")
      | OK
</template>

<script>
export default {
  name: 'VDateField',

  props: {
    left: {
      type: Boolean,
      default: false
    },
    value: String,
    label: String
  },

  mounted () {
    this.date = this.value
  },

  data: () => ({
    date: null,
    showMenu: false
  }),

  methods: {
    onSave () {
      this.$refs.menu.save(this.date)
      this.$emit('input', this.date)
    }
  },

  watch: {
    value (value) {
      this.date = value
    }
  }
}
</script>
