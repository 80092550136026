<template>
  <div
    :style="{ paddingBottom: expanded ? null : 0 }"
    class="toolbar toolbar--dense toolbar__group"
  >
    <div class="toolbar__header">
      <t-toggler
        block
        v-model="expanded"
      >
        <span class="toolbar__title">{{ title }}</span>
      </t-toggler>
    </div>
    <template v-if="expanded">
      <slot />
    </template>
  </div>
</template>

<script>
import TToggler from '../t-toggler/TToggler.vue'

export default {
  components: {
    TToggler
  },

  props: {
    title: String,
    initialState: {
      type: String,
      default: 'opened',
      validator (value) {
        return ['closed', 'opened'].includes(value)
      }
    }
  },

  data: () => ({
    expanded: true
  }),

  mounted () {
    this.expanded = this.initialState === 'opened'
  }
}
</script>
