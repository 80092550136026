<template lang="pug">
  .v-chip-select
    v-chip.mr-2(
      v-for="(item, index) in options"
      :key="index"
      :color="isActive(item) ? 'black' : 'gray'"
      :text-color="isActive(item) ? 'white' : 'black'"
      @click="onSelect(item)")
      | {{ item.title }}
</template>

<script>
export default {
  name: 'VChipSelect',

  props: {
    options: Array,
    value: String
  },

  methods: {
    onSelect (item) {
      this.$emit('input', item.key)
    },

    isActive (item) {
      return item.key === this.value
    }
  }
}
</script>

<style lang="scss">
  .v-chip-select {
    display: flex;
    padding-top: .5rem;
  }
</style>
