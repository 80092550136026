import ColorPicker from './color-picker/ColorPicker'
import ColorPickerInput from './color-picker/ColorPickerInput'
import VChipSelect from '@/components/ui/v-chip-select/VChipSelect'
import VDateField from '@/components/ui/v-date-field/VDateField'
import VDropdown from '@/components/ui/v-dropdown/VDropdown'
import VDrawer from '@/components/ui/v-drawer/VDrawer'

export const registerUIComponents = (Vue) => {
  Vue.component('ColorPicker', ColorPicker)
  Vue.component('ColorPickerInput', ColorPickerInput)
  Vue.component('VChipSelect', VChipSelect)
  Vue.component('VDateField', VDateField)
  Vue.component('VDropdown', VDropdown)
  Vue.component('VDrawer', VDrawer)
}
