<template lang="pug">
.date-settings-item
  .date-settings-item__title
    v-menu(
      left
      bottom
      :close-on-content-click="false")
      template(v-slot:activator="{ on, attrs }")
        .date-settings-item__value.mr-3(
          v-bind="attrs"
          v-on="on")
          | {{ value || name }}
      v-date-picker(
        :value="value || new Date().toISOString().substring(0, 10)"
        :first-day-of-week="1"
        @change="update")
  button.btn(@click="update(null)")
    v-icon(small) mdi-delete
</template>

<script>
export default {
  name: 'DateSettingsItem',

  props: {
    name: String,
    value: String
  },

  methods: {
    update (date) {
      this.$emit('update', date)
    }
  }
}
</script>

<style lang="scss">
.date-settings-item {
  height: 32px;
  font-size: 13px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__value {
    font-size: 13px;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
