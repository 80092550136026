<template lang="pug">
.range
  .title {{ name }}
  .action
    v-slider(
      :step="0.01"
      :max="item?.max ?? 100"
      :min="item?.min ?? 0"
      :value="value"
      dense
      hide-details
      @change="onUpdate")
      template(v-slot:append)
        input.input-filed(
          type="number"
          :value="value"
          @blur="onInput")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },

  methods: {
    onUpdate (value) {
      this.$emit('update', value)
    },

    onInput (e) {
      this.$emit('update', parseFloat(e.target.value))
    }
  }
}
</script>

<style>
.range .v-input {
  align-items: center;
}
</style>

<style lang="scss" scoped>
  .range {
    height: 32px;
    padding-left: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: 13px !important;
    font-weight: normal !important;
  }

  .action {
    width: calc(100% - 50px);
  }

  .input-filed {
    cursor: default;
    height: 28px;
    width: 36px;
    padding-left: 7px;
    padding-right: 7px;
    margin-left: -6px;
    border: 1px solid transparent;
    font-size: 11px;
    text-align: right;
    font-family: 'Inter';

    &:hover {
      border: 1px solid rgba(0, 0, 0, .1);
    }

    &:focus {
      border: 1px solid var(--color-primary);
      outline: 1px solid var(--color-primary);
      outline-offset: -2px;
    }
  }

  .input-filed::-webkit-outer-spin-button,
  .input-filed::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
