import {
  doc,
  query,
  collection,
  orderBy,
  onSnapshot,
  addDoc,
  setDoc,
  getFirestore,
  deleteDoc
} from 'firebase/firestore'

const db = getFirestore()
const COLLECTION = 'transactions'

const SET_ITEMS = 'SET_ITEMS'
const SET_UNSUBSCRIBE = 'SET_UNSUBSCRIBE'

function restoreData () {
  return JSON.parse(sessionStorage.getItem(COLLECTION)) ?? []
}

function saveData (data) {
  sessionStorage.setItem(COLLECTION, JSON.stringify(data))
}

function getUpdatedBalance (balance, transaction) {
  return transaction.type === 'inc'
    ? balance + transaction.amount
    : balance - transaction.amount
}

export default {
  namespaced: true,

  state: {
    items: restoreData(),
    unsubscribe: null
  },

  getters: {
    items: (state) => state.items
  },

  mutations: {
    [SET_ITEMS] (state, data) {
      state.items = data
      saveData(data)
    },

    [SET_UNSUBSCRIBE] (state, data) {
      state.unsubscribe = data
    }
  },

  actions: {
    load ({ state, commit }) {
      if (!state.unsubscribe) {
        const q = query(collection(db, COLLECTION), orderBy('createdAt', 'desc'))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = []
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.id,
              ...doc.data()
            }
            items.push(data)
          })
          commit(SET_ITEMS, items)
        })
        commit(SET_UNSUBSCRIBE, unsubscribe)
      }
    },

    async create ({ dispatch }, data) {
      const balanceId = data.balance
      const balanceData = await dispatch('finances/balances/getById', balanceId, { root: true })
      const balancePayload = {
        id: balanceId,
        data: {
          balance: getUpdatedBalance(balanceData.balance, data)
        }
      }
      await dispatch('finances/balances/update', balancePayload, { root: true })
      return addDoc(collection(db, COLLECTION), {
        ...data,
        balance: {
          id: balanceData.id,
          name: balanceData.name,
          currency: balanceData.currency
        }
      })
    },

    update (_, { id, data }) {
      return setDoc(doc(db, COLLECTION, id), data, { merge: true })
    },

    delete (_, id = null) {
      return deleteDoc(doc(db, COLLECTION, id))
    }
  }
}
