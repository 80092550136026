import authStore from './store'
import { db } from '../util/firebase.js'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'

export const useAuth = (app, store, router) => {
  store.registerModule('auth', authStore)

  const auth = getAuth()

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      onSnapshot(doc(db, 'users', user.uid), async (querySnapshot) => {
        localStorage.setItem('uid', user.uid)
        if (querySnapshot.exists()) {
          const data = {
            displayName: user.displayName,
            email: user.email,
            ...querySnapshot.data()
          }
          if (!app.$route.path.includes('app')) {
            await router.replace('/app')
          }
          store.dispatch('auth/initUser', data)
        } else {
          signOut(auth)
          router.replace('/login')
        }
      })
    } else {
      if (app.$route.path !== '/login') {
        await router.replace('/login')
      }
      store.dispatch('auth/initUser')
    }
  })
}
