import { execute } from '../utils'

const ENDPOINT = 'websites'

export default {
  /**
   * Loads website object from a server, alredy mixed with order data. Works for website with linked orders only.
   * @param {String} id website id
   * @returns Website object
   */
  getById (id) {
    return execute({
      url: `${ENDPOINT}/${id}`
    })
  },

  /**
   * Creates a new website by passed preset and link it to an order.
   * @param {*} payload { presetId: 'PRESET_ID', orderId: 'ORDER_ID' }
   * @returns next structure { data: 'WEBSITE', success: BOOL }
   */
  createByPreset (payload) {
    return execute({
      method: 'POST',
      url: `${ENDPOINT}/create-by-preset`,
      body: payload
    })
  },

  /**
   * Creates a new preset based on passed website
   * @param {*} id website id
   * @param {*} payload { name: 'PRESET_NAME' }
   * @returns next structure { data: 'PRESET', success: BOOL }
   */
  saveAsPreset (id, payload) {
    return execute({
      method: 'POST',
      url: `${ENDPOINT}/${id}/save-as-preset`,
      body: payload
    })
  },

  /**
   * Saves website snapshot with same ID as website to snapshots collection.
   * @param {String} id website id
   * @returns next structure { data: 'SNAPSHOT', success: 'BOOL' }
   */
  makeSnapshot (id) {
    return execute({
      method: 'POST',
      url: `${ENDPOINT}/${id}/make-snapshot`
    })
  }
}
