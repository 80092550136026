<template>
  <div :data-lang="selectedLang">
    <PropertyLangSelect
      v-model="selectedLang"
    />
    <v-textarea
      outlined
      dense
      hide-details
      v-model="textValue"
    />
  </div>
</template>

<script>
import PropertyLangSelect from './PropertyLangSelect.vue'

export default {
  components: {
    PropertyLangSelect
  },

  props: {
    value: String
  },

  data: () => ({
    selectedLang: 'uk'
  }),

  computed: {
    textValue: {
      get () {
        if (this.value) {
          const obj = JSON.parse(this.value)
          return obj[this.selectedLang]
        }
        return null
      },
      set (value) {
        const obj = this.value
          ? JSON.parse(this.value)
          : {}
        obj[this.selectedLang] = value
        this.$emit('input', JSON.stringify(obj))
      }
    }
  }
}
</script>
