<template>
  <router-view
    v-if="inited"
  />
</template>

<script>

export default {
  name: 'App',

  computed: {
    inited () {
      return this.$store.getters['auth/isAppInited']
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/main.scss';
</style>
