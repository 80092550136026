import {
  ref,
  getStorage,
  uploadBytes,
  deleteObject,
  getDownloadURL
} from 'firebase/storage'
import { firebaseApp } from '@/util/firebase.js'
import { open, openJson, extractFilePath } from './utils'
import filesStore from './store'

const storage = getStorage(firebaseApp)

const WEBSITE_FILES_FOLDER = 'websites'

const upload = (file, name, group, callback) => {
  const storageRef = ref(storage, `${WEBSITE_FILES_FOLDER}/${group}/${name}`)
  uploadBytes(storageRef, file).then(async () => {
    const src = await getDownloadURL(storageRef)
    callback && callback({
      src,
      name
    })
  })
}

const DEFAULT_OPTIONS = {
  group: 'general',
  name: 'default',
  accept: '*'
}

export default {
  install (Vue, store) {
    store.registerModule('storage', filesStore)

    Vue.prototype.$files = {
      open,
      openJson,
      openAndUpload: (options = {}, callback) => {
        const { accept, group } = {
          ...DEFAULT_OPTIONS,
          ...options
        }
        open(accept, async (file) => {
          upload(file, file.name, group, callback)
        })
      },
      upload: (file, options = DEFAULT_OPTIONS, callback) => {
        const { name, group } = {
          ...DEFAULT_OPTIONS,
          ...options
        }
        upload(file, name, group, callback)
      },
      delete: ({ src }) => {
        if (src) {
          const path = extractFilePath(src)
          return deleteObject(ref(storage, path))
        }
      }
    }
  }
}
