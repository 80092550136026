import finances from './store'

const NAVIGATION = {
  label: 'Finances',
  path: 'finances',
  adminOnly: true
}

export default {
  install (_, store) {
    store.registerModule('finances', finances)
    store.dispatch('app/registerAppNav', NAVIGATION)
  }
}
