import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import { TAGS } from '../utils/consts'
import { getOrderPrice } from '@/util/utils.js'

dayjs.extend(isSameOrAfter)

const SET_FILTERS = 'SET_FILTERS'

const TYPES = {
  INCOME: 'inc',
  EXPENSE: 'exp'
}

export default {
  namespaced: true,

  state: {
    filters: {
      createdAt: null
    }
  },

  getters: {
    items (state, __, ___, rootGetters) {
      return rootGetters['finances/transactions/items']
        .filter(item => item.createdAt.includes(state.filters.createdAt || ''))
    },
    totalExpenses (_, getters) {
      return getters.items
        .filter(item => item.type === TYPES.EXPENSE && item.tag !== 'equipment')
        .reduce((acc, curr) => acc + curr.amount, 0)
    },
    totalIncomes (_, getters) {
      return getters.items
        .filter(item => item.type === TYPES.INCOME)
        .reduce((acc, curr) => acc + curr.amount, 0)
    },
    saleIncomes (_, getters) {
      return getters.items
        .filter(item => item.tag === 'sale')
        .reduce((acc, curr) => acc + curr.amount, 0)
    },
    incomesByTags (_, getters, __, rootGetters) {
      const amountInOrders = rootGetters['orders/items']
        .filter(item => item.status !== 'archived' && item.payment.status === 'pending')
        .reduce((acc, item) => acc + getOrderPrice(item), 0)
      const result = TAGS
        .filter(tag => tag.type.includes(TYPES.INCOME))
        .map(tag => ({
          name: tag.name,
          value: getters.items
            .filter(transaction => transaction.type === TYPES.INCOME && transaction.tag === tag.key)
            .reduce((acc, cur) => acc + cur.amount, 0)
        }))
        .filter(item => item.value !== 0)
      return [
        ...result,
        {
          name: 'In orders',
          value: amountInOrders
        }
      ]
    },
    expensesByTags (_, getters) {
      return TAGS
        .filter(tag => tag.type.includes(TYPES.EXPENSE))
        .map(tag => ({
          name: tag.name,
          value: getters.items
            .filter(transaction => transaction.type === TYPES.EXPENSE && transaction.tag === tag.key)
            .reduce((acc, cur) => acc + cur.amount, 0)
        }))
        .filter(item => item.value !== 0)
    }
  },

  mutations: {
    [SET_FILTERS] (state, filters) {
      for (const key in filters) {
        state.filters[key] = filters[key]
      }
    }
  },

  actions: {
    filterData ({ commit }, filters) {
      commit(SET_FILTERS, filters)
    }
  }
}
