export default [
  {
    path: 'builder/:id',
    component: () => import('../views/Index.vue')
  },
  {
    path: 'builder/new/:orderId',
    component: () => import('../views/Index.vue')
  }
]
