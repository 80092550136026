<template>
  <div
    :style="styles"
    class="input-range_point"
  >
    <span :style="{ backgroundColor: color }"/>
  </div>
</template>

<script>
function getPointerPosition (event) {
  return {
    pageX: event.changedTouches ? event.changedTouches[0].pageX : event.pageX,
    pageY: event.changedTouches ? event.changedTouches[0].pageY : event.pageY
  }
}

export default {
  props: {
    color: String,
    active: Boolean,
    value: [Number, Object],
    mode: {
      type: String,
      default: 'slider' // slider|area
    },
    size: {
      type: Number,
      default: 20
    }
  },

  data: () => ({
    bounding: {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    },
    pos: {
      x: 0,
      y: 0
    }
  }),

  computed: {
    width () {
      return this.mode === 'slider'
        ? this.bounding.width - this.size
        : this.bounding.width
      },

    styles () {
      return {
        top: this.mode === 'slider' ? '50%' : '0',
        transform: this.mode === 'slider'
         ? `translate3d(${this.pos.x}px, -50%, 0px)`
         : `translate3d(${this.pos.x}px, ${this.pos.y}px, 0px)`
      }
    }
  },

  watch: {
    value (value) {
      this.setPos(value)
    }
  },

  mounted () {
    this.setPos(this.value)
    this.$parent.$el.addEventListener('mousedown', this.onMousedown)
  },

  methods: {
    onMousedown (event) {
      if (this.active) {
        const rect = this.$parent.$el.getBoundingClientRect()
        this.bounding.top = rect.top
        this.bounding.left = rect.left
        this.bounding.width = this.$parent.$el.offsetWidth
        this.bounding.height = this.$parent.$el.offsetHeight
        this.onMousemove(event)
        document.onmousemove = this.onMousemove
        document.onmouseup = this.onMouseup
      }
    },

    onMouseup () {
      document.onmousemove = null
      document.onmouseup = null
    },

    onMousemove (event) {
      event.preventDefault()
      const pointer = getPointerPosition(event)
      const shiftY = pointer.pageY - (this.bounding.top + this.size / 2)
      const shiftX = pointer.pageX - (this.bounding.left + this.size / 2)

      this.pos.x = this.getPosX(shiftX) // px
      this.pos.y = this.getPosY(shiftY) // px

      const left = this.mode === 'area' // %
        ? (this.pos.x + 10) / this.bounding.width * 100
        : this.pos.x / this.width * 100

      const top = this.mode === 'area' // %
        ? (this.pos.y + 10) / this.bounding.height * 100
        : 0

      this.$emit('change', { left, top })
    },

    setPos (value) {
      if (typeof value === 'object') {
        this.pos.y = value.top * 240 / 100 - this.size / 2
        this.pos.x = value.left * 240 / 100 - this.size / 2
      } else {
        this.pos.x = value * 200 / 100
      }
    },

    getPosX (value) {
      const from = this.mode === 'area'
        ? -this.size / 2
        : 0
      const to = this.mode === 'area'
        ? this.bounding.width - this.size / 2
        : this.bounding.width - this.size

      return this.applyBounding(value, from, to)
    },

    getPosY (value) {
      if (this.mode === 'area') {
        const from = -this.size / 2
        const to = this.bounding.height - this.size / 2
        return this.applyBounding(value, from, to)
      }
      return 0
    },

    applyBounding (value, min, max) {
      return Math.min(Math.max(value, min), max)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-range_point {
  position: absolute;
  z-index: 2;

  height: 20px;
  width: 20px;
  border-radius: 15px;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0 0 0 1px,
    rgba(0, 0, 0, 0.05) 0 10px 10px -5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline;
    height: 10px;
    width: 10px;
    pointer-events: none;
    border-radius: 50%;
    box-shadow: inset rgba(0, 0, 0, 0.15) 0 0 0 1px;
  }
}
</style>
