import { APP_NAME, TEMPLATE_APP_NAME } from '@/util/config.js'

const SET_PREVIEW_IFRAME = 'SET_PREVIEW_IFRAME'
const SET_IFRAME_MESSAGE_HANDLER = 'SET_IFRAME_MESSAGE_HANDLER'

const getIFrameMessageHandler = (dispatch) => {
  return (event) => {
    if (event.data.target === APP_NAME) {
      const { id, ...data } = event.data.data
      dispatch('builder/website/update', { id, data }, { root: true })
    }
  }
}

export default {
  namespaced: true,

  state: {
    previewIframe: null,
    iframeMessageHandler: null
  },

  mutations: {
    [SET_PREVIEW_IFRAME] (state, value) {
      state.previewIframe = value
    },

    [SET_IFRAME_MESSAGE_HANDLER] (state, value) {
      state.iframeMessageHandler = value
    }
  },

  actions: {
    reload ({ state }) {
      const window = state.previewIframe?.contentWindow
      if (window) {
        const url = state.previewIframe.src ||
          this._vm.$templateUrl
        state.previewIframe.src = url
        // window.postMessage({
        //   target: TEMPLATE_APP_NAME,
        //   event: 'reload'
        // }, url)
      }
    },

    update ({ state }, data) {
      const window = state.previewIframe?.contentWindow
      if (window) {
        const url = state.previewIframe.src ||
          this._vm.$templateUrl
        window.postMessage({
          data,
          target: TEMPLATE_APP_NAME,
          event: 'update'
        }, url)
      }
    },

    subscribeToUpdates ({ dispatch, commit }) {
      const handler = getIFrameMessageHandler(dispatch)
      window.addEventListener('message', handler)
      commit(SET_IFRAME_MESSAGE_HANDLER, handler)
    },

    unsubscribeFromUpdates ({ state, commit }) {
      window.removeEventListener('message', state.iframeMessageHandler)
      commit(SET_IFRAME_MESSAGE_HANDLER, null)
    }
  }
}
