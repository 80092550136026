import settings from './store'

import PropertiesList from './views/widgets/PropertiesList'

const NAVIGATION = {
  label: 'Settings',
  path: 'settings',
  class: 'hide-on-mobile',
  adminOnly: true
}

export default {
  install (Vue, store) {
    store.registerModule('settings', settings)
    store.dispatch('app/registerAppNav', NAVIGATION)
    Vue.component('PropertiesList', PropertiesList)
  }
}
