import { EXTRACT_WEBSITE_COLORS } from '../utils'

export default {
  namespaced: true,

  getters: {
    colors: (_, __, ___, rootGetters) => {
      return EXTRACT_WEBSITE_COLORS(rootGetters['builder/website/website'])
    },
    swatches: (_, getters) => [null, ...getters.colors],
    selectedWidget: (_, __, ___, rootGetters) => {
      const website = rootGetters['builder/website/website']
      if (website?.builder) {
        return website.widgets
          .find(item => item.id === website.builder.selectedWidget)
      }
      return null
    },
    selectedPage: (_, __, ___, rootGetters) => {
      const website = rootGetters['builder/website/website']
      if (website?.builder) {
        return website.pages
          .find(item => item.path === website.builder.selectedPage)
      }
      return null
    }
  }
}
