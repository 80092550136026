<template>
  <div class="input-range">
    <div
      :style="styles"
      class="input-range_track"
    />
    <ColorPickerPoint
      v-for="(point, index) of points"
      :key="index"
      :color="point.color"
      :active="activeIndex === index"
      :value="getPercent(point.left)"
      @mousedown.native="onSelect(index)"
      @change="onChange"
    />
  </div>
</template>

<script>
import { RGBAtoCSS, PointsToCSSGradient } from './utils'
import ColorPickerPoint from './ColorPickerPoint.vue'

export default {
  name: 'ColorPickerRange',

  components: {
    ColorPickerPoint
  },

  props: {
    color: [String, Object],
    value: [Number, Array],
    background: [String, Array],
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },

  data: () => ({
    activeIndex: 0
  }),

  computed: {
    isMultipointMode () {
      return Array.isArray(this.value)
    },

    styles () {
      return {
        '--input-range-track-background': this.isMultipointMode
          ? PointsToCSSGradient(this.value)
          : Array.isArray(this.background)
            ? `linear-gradient(to right, ${this.background})`
            : `linear-gradient(to right, ${this.background} ${this.percent}%, #eee ${this.percent}%)`
      }
    },

    percent () {
      return this.getPercent(this.value)
    },

    points () {
      if (this.isMultipointMode) {
        return this.value.map(p => ({
          color: RGBAtoCSS(p),
          left: p.left
        }))
      }
      return [{
        color: this.color,
        left: this.value
      }]
    }
  },

  methods: {
    getPercent (value) {
      return value / (this.max - this.min) * 100
    },

    onSelect (index) {
      this.activeIndex = index
      const value = this.isMultipointMode
        ? this.value[index]
        : this.value
      this.$emit('select', value)
    },

    onChange ({ left }) {
      const range = this.max - this.min
      const value = range / 100 * left
      if (this.isMultipointMode) {
        const points = [...this.value]
        points[this.activeIndex].left = value
        this.$emit('input', points)
      } else {
        this.$emit('input', value)
      }
    }
  },

  watch: {
    color: {
      handler (color) {
        if (typeof color === 'object') {
          const { r, g, b, a } = color
          const points = [...this.value]
          points[this.activeIndex].r = r
          points[this.activeIndex].g = g
          points[this.activeIndex].b = b
          points[this.activeIndex].a = a
          this.$emit('input', points)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-range {
  position: relative;
  height: 26px;

  &_track {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    transform: translate3d(0, -50%, 0);
    box-shadow: inset rgba(0, 0, 0, 0.08) 0 0 0 1px;
    background: repeating-conic-gradient(#ddd 0% 25%, transparent 0% 50%) 50% / 5px 5px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 10px;
      background: var(--input-range-track-background);
    }
  }
}
</style>
