<template>
  <div
    class="t-toggler"
    :class="{ 't-toggler--block': block }"
    @click="toggle"
  >
    <slot />
    <svg
      fill="none"
      viewBox="0 0 10 6"
      xmlns="http://www.w3.org/2000/svg"
      class="t-toggler__chevron"
      :style="{ transform: value ? 'rotate(0deg)' : 'rotate(-90deg)' }"
    >
      <path
        vector-effect="non-scaling-stroke"
        d="M1 1L5.18182 5L9 1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    block: Boolean
  },

  methods: {
    toggle () {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss">
.t-toggler {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--block {
    width: 100%;
    justify-content: space-between;
  }

  &__chevron {
    width: 10px;
    height: 10px;
    fill: none;
    margin-left: 8px;
    transform-origin: 50% 50%;
    transition: transform 150ms ease-out 0s;
  }

  &--block {
    margin-right: 4px;
  }
}
</style>
