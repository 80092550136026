<template>
  <div
    class="v-dropdown"
    :class="classes"
    @click.self="onClose"
  >
    <Transition name="slide-right">
      <div
        v-if="visible"
        class="content"
        :style="contentStyles"
      >
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'right'
    },
    width: {
      type: String,
      default: '30vw'
    },
    minWidth: {
      type: String,
      default: '400px'
    }
  },

  data: () => ({
    visible: false
  }),

  computed: {
    classes () {
      return {
        [`v-dropdown--${this.position}`]: true,
        'v-dropdown--overlay': this.visible
      }
    },

    contentStyles () {
      return {
        width: this.width,
        minWidth: this.minWidth
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.visible = true
    })
  },

  methods: {
    onClose () {
      this.visible = false
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.v-dropdown {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: .3s;
    background-color: #0009;
  }

  &--overlay::before {
    opacity: 1;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  --height: calc(100dvh - 40px);

  .content {
    position: relative;
    overflow: hidden;
    height: var(--height);
    border-radius: var(--border-radius);
  }
}
</style>
