import { deepClone } from '@/util/utils.js'

const SET_SNAPSHOTS = 'SET_SNAPSHOTS'

export default {
  namespaced: true,

  state: {
    snapshots: []
  },

  getters: {
    isEmpty: (state) => {
      return state.snapshots.length === 0
    }
  },

  mutations: {
    [SET_SNAPSHOTS] (state, value) {
      state.snapshots = value
    }
  },

  actions: {
    capture ({ state, commit, rootGetters }, { data, hash }) {
      const website = deepClone(rootGetters['builder/website/website'])
      const snap = { hash }
      for (const key in data) {
        snap[key] = website[key]
      }
      commit(SET_SNAPSHOTS, [...state.snapshots, snap])
    },

    undo ({ state, commit, dispatch }) {
      const snap = state.snapshots.slice(-1)
      const snapshots = state.snapshots.slice(0, -1)
      commit(SET_SNAPSHOTS, snapshots)
      if (snap.length) {
        dispatch('builder/website/forceWebsiteUpdate', snap[0], { root: true })
      }
    },

    clear ({ commit }) {
      commit(SET_SNAPSHOTS, [])
    }
  }
}
