import { TYPES } from './consts'

export const EXTRACT_WEBSITE_COLORS = (website) => {
  if (website) {
    const colors = [...Object.values(website.styles.colors)]
    website.widgets.forEach(item => {
      for (const key in item.config) {
        const property = item.config[key]
        if (property.type === TYPES.COLOR) {
          colors.push(property.value)
        }
      }
    })
    return [...new Set(colors
      .filter(item => item !== null)
      .sort())]
  }
  return []
}
