<template>
  <div class="color-picker_area">
    <canvas
      ref="canvas"
      height="240"
      width="240"
    />
    <ColorPickerPoint
      ref="point"
      active
      mode="area"
      :color="pointColor"
      :value="{
        top: 100 - value.v,
        left: value.s
      }"
      @change="onChange"
    />
  </div>
</template>

<script>
import { HSVAtoRGBA } from './utils'
import ColorPickerPoint from './ColorPickerPoint.vue'

export default {
  components: {
    ColorPickerPoint
  },

  props: {
    value: Object
  },

  data: () => ({
    ctx: null,
    width: 240,
    height: 240
  }),

  computed: {
    hue () {
      return this.value.h
    },

    pointColor () {
      const rgba = HSVAtoRGBA(this.value)
      return `rgb(${rgba.r},${rgba.g},${rgba.b})`
    }
  },

  methods: {
    onChange (value) {
      this.$emit('input', {
        h: this.value.h,
        s: value.left,
        v: 100 - value.top,
        a: this.value.a
      })
    },

    update () {
      const rgbaColor = `hsl(${this.hue}deg, 100%, 50%)`
      this.ctx.rect(0, 0, this.width, this.height)
      this.ctx.fillStyle = rgbaColor
      this.ctx.fillRect(0, 0, this.width, this.height)

      const grdWhite = this.ctx.createLinearGradient(0, 0, this.width, 0)
      grdWhite.addColorStop(0, 'rgba(255,255,255,1)')
      grdWhite.addColorStop(1, 'rgba(255,255,255,0)')
      this.ctx.fillStyle = grdWhite
      this.ctx.fillRect(0, 0, this.width, this.height)

      const grdBlack = this.ctx.createLinearGradient(0, 0, 0, this.height)
      grdBlack.addColorStop(0, 'rgba(0,0,0,0)')
      grdBlack.addColorStop(1, 'rgba(0,0,0,1)')
      this.ctx.fillStyle = grdBlack
      this.ctx.fillRect(0, 0, this.width, this.height)
    }
  },

  mounted () {
    const canvas = this.$refs.canvas
    this.ctx = canvas.getContext('2d')
    this.width = canvas.width
    this.height = canvas.height
    this.$refs.point.left = (this.width * this.value.h / 100) - 10
    this.$refs.point.top = (this.height - (this.height * this.value.v / 100)) - 10
    this.update()
  },

  watch: {
    hue () {
      this.update()
    }
  }
}
</script>

<style scoped>
.color-picker_area {
  position: relative;
  height: 240px;
  width: 240px;
}
</style>
