const executeWorkflow = (websiteId, params) => {
  const payload = {
    event_type: `${params.subdomain}.invito.link`,
    client_payload: {
      websiteId,
      ...params
    }
  }
  return fetch('https://api.github.com/repos/invito-link/invito-template/dispatches', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.VUE_APP_GITHUB}`
    },
    body: JSON.stringify(payload)
  })
}

export default {
  executeWorkflow
}
