import { ORDER_OPTIONS, ORDER_PACKAGES } from './consts'

export const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const downloadObjectAsJson = (exportObj, exportName) => {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export const log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`%c ${args[0]}`, 'color: #fff; background-color: #42b883;', ...args.slice(1))
  }
}

export const openInNewTab = url => {
  window.open(url, '_blank').focus()
}

export const generateId = (len = 6) => {
  const SYMBOLS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' // 62
  let id = ''
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * SYMBOLS.length)
    id += SYMBOLS.charAt(index)
  }
  return id
}

export const formatOrderNumber = (number) => {
  const SYMBOLS = '9PMWKIRYF8D517STCXNEBJU2OH4V603AQGZL' // 36
  const COUNT = SYMBOLS.length
  let result = ''
  let n = number
  do {
    const i = n % COUNT
    result = SYMBOLS[i] + result
    n = Math.floor(n / COUNT)
  } while (n > 0)
  return result.padStart(3, '0')
}

export const getOrderPrice = (order) => {
  return ORDER_OPTIONS
    .filter(item => order.options[item.key])
    .reduce((acc, item) => acc + item.price, 0) +
    ORDER_PACKAGES.find(item => item.key === order.package)
    ?.price - order.payment.discount
}

export const isObject = value => typeof value === 'object' && value !== null

export const deepClone = obj => {
  const result = {}
  if (isObject(obj)) {
    if (Array.isArray(obj)) {
      return obj.map(item => deepClone(item))
    } else {
      for (const key in obj) {
        result[key] = deepClone(obj[key])
      }
    }
  } else {
    return obj
  }
  return result
}

export const isObjectEmpty = obj => {
  return obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
}

export const urlToBlob = (url) => fetch(url).then(r => r.blob())

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const generateSubdomain = (len = 5) => {
  const SYMBOLS = '0123456789abcdefghijklmnopqrstuvwxyz' // 36
  let id = ''
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * SYMBOLS.length)
    id += SYMBOLS.charAt(index)
  }
  return id
}

/* eslint-disable */
export const transliterate = (name) => {
  const ukrainianToEnglishMap = {
      'а': 'a', 'б': 'b', 'в': 'v', 'г': 'h', 'ґ': 'g',
      'д': 'd', 'е': 'e', 'є': 'ie', 'ж': 'zh', 'з': 'z',
      'и': 'y', 'і': 'i', 'ї': 'i', 'й': 'i', 'к': 'k',
      'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p',
      'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f',
      'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch',
      'ь': '', 'ю': 'iu', 'я': 'ia'
  }
  return name.replace(/[а-яґєії]/ig, char => ukrainianToEnglishMap[char.toLowerCase()] || char)
}

export const strToBase64 = (str) => {
  const bytes = new TextEncoder().encode(str)
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('')
  return btoa(binString)
}
