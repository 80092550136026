import { transliterate, generateSubdomain } from '@/util/utils.js'

export default {
  namespaced: true,

  getters: {
    options: (_, __, ___, rootGetters) => {
      const order = rootGetters['builder/website/order']
      const subdomainOptions = order?.website.subdomainOptions
      return subdomainOptions
        ? Object
          .keys(subdomainOptions)
          .map(key => ({ key, ...subdomainOptions[key] }))
          .sort((a, _) => a.key === 'base' ? -1 : 1)
        : []
    }
  },

  actions: {
    async isAvailable ({ dispatch }, value) {
      const exists = await dispatch(
        'orders/exists',
        { field: 'website.subdomain', value },
        { root: true }
      )
      return !exists
    },

    async generate ({ dispatch, rootGetters }, { orderId }) {
      const order = rootGetters['builder/website/order']
      if (!order) return
      if (order.website.subdomainOptions) return
      if (!order.bride || !order.bridegroom) return
      const brideName = transliterate(order.bride).toLowerCase()
      const bridegroomName = transliterate(order.bridegroom).toLowerCase()
      const subdomainBase = generateSubdomain()
      const subdomainCustom = `${bridegroomName}-${brideName}`
      const subdomainOptions = {
        base: {
          value: subdomainBase,
          available: await dispatch('isAvailable', subdomainBase)
        },
        custom: {
          value: subdomainCustom,
          available: await dispatch('isAvailable', subdomainCustom)
        }
      }
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      await dispatch(
        'orders/update',
        {
          id: orderId,
          data: {
            website: {
              subdomainOptions
            }
          }
        },
        { root: true }
      )
    }
  }
}
