import dashboard from './store'

const NAVIGATION = {
  label: 'Dashboard',
  path: 'dashboard',
  adminOnly: true
}

export default {
  install (_, store) {
    store.registerModule('dashboard', dashboard)
    store.dispatch('app/registerAppNav', NAVIGATION)
  }
}
