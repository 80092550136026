import { encode } from 'blurhash'
import Compressor from 'compressorjs'

export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (...args) => reject(args)
    img.src = src
  })
}

export const loadImageAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

export const getImageData = (image) => {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const context = canvas.getContext('2d')
  context.drawImage(image, 0, 0)
  return context.getImageData(0, 0, image.width, image.height)
}

export const resizeAndCompress = (file, options = {}) => {
  return new Promise((resolve, reject) => {
    return new Compressor(file, {
      ...options,
      success (result) {
        resolve(result)
      },
      error (err) {
        reject(err)
      }
    })
  })
}

export const encodeImageToBlurhash = (image) => {
  const imageData = getImageData(image)
  return encode(imageData.data, imageData.width, imageData.height, 4, 4)
}

export const copyImageToClipboard = (url, callback) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(url).then(async (res) => {
        await navigator.clipboard.write([
          new ClipboardItem({
            'image/png': await res.blob()
          })
        ])
        callback && callback()
        resolve()
      })
    } catch (error) {
      console.error(error)
      reject(error)
    }
  })
}
