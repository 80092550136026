<template lang="pug">
.config-items
  template(v-for="item in items")
    component(
      v-if="item.enabled || sub"
      :is="`${item.type.toLowerCase()}-config-item`"
      :item="item.type === 'LIST' ? item : undefined"
      :name="item.name"
      :value="item.value"
      :options="item.options"
      :configSelectedLang="configSelectedLang"
      :bindValueToLanguage="item.bindValueToLanguage"
      @update="$emit('update', { value: $event, item })"
      @sub-update="$emit('sub-update', $event)")
</template>

<script>
import BoolConfigItem from './BoolConfigItem'
import ColorConfigItem from './ColorConfigItem'
import DateConfigItem from './DateConfigItem'
import ImageConfigItem from './ImageConfigItem'
import LinkConfigItem from './LinkConfigItem'
import ListConfigItem from './ListConfigItem'
import SelectConfigItem from './SelectConfigItem'
import SliderConfigItem from './SliderConfigItem'
import StringConfigItem from './StringConfigItem'
import TextConfigItem from './TextConfigItem'
import TimeConfigItem from './TimeConfigItem'
import FileConfigItem from './FileConfigItem'

export default {
  name: 'ConfigItems',

  components: {
    BoolConfigItem,
    ColorConfigItem,
    DateConfigItem,
    ImageConfigItem,
    LinkConfigItem,
    ListConfigItem,
    SelectConfigItem,
    SliderConfigItem,
    StringConfigItem,
    TextConfigItem,
    TimeConfigItem,
    FileConfigItem
  },

  props: {
    sub: Boolean,
    config: Object,
    configSelectedLang: String
  },

  computed: {
    showSubItems () {
      return !this.config.type ||
        this.config.type.value === 'options'
    },

    items () {
      return this.config
        ? Object
            .keys(this.config)
            .map(key => ({
              ...this.config[key],
              key
            }))
            .filter(item => item.type !== 'UID')
            .filter(item => !this.sub || item.type !== 'LIST' || this.showSubItems)
            .sort((a, b) => a.order - b.order)
        : []
    }
  }
}
</script>
