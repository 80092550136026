import Vue from 'vue'
import vuescroll from 'vuescroll'

/* @ts-ignore */
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'

import { useAuth } from './auth'
import { registerIcons } from './components/icons'
import { registerUIComponents } from './components/ui'
import { registerFilters } from './filters'
import { registerMixins } from './mixins/global'
import { registerModules } from './modules'

Vue.config.productionTip = false

Vue.use(vuescroll, {
  name: 'v-scroll',
  ops: {
    bar: {
      opacity: '0.3',
      background: '#000'
    }
  }
})

registerIcons(Vue)
registerUIComponents(Vue)
registerFilters(Vue)
registerMixins(Vue)
registerModules(Vue, store, router)

const app = new Vue({
  /* @ts-ignore */
  vuetify,
  router,
  store,
  render: h => h(App)
})

useAuth(app, store, router)

app.$mount('#app')
