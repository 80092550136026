<template>
  <div>
    <draggable v-model="properties">
      <PropertiesListItem
        v-for="property in properties"
        :id="id"
        :key="`${id}_${property.key}`"
        :property="property"
        :is-sub="isSub"
        @remove="onPropertyRemove"
        @update="onPropertyUpdate($event, property.key)"
      />
    </draggable>
    <v-btn
      block
      depressed
      color="primary"
      class="mt-2"
      @click="addPropperty"
    >
      Add property
    </v-btn>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import PropertiesListItem from './PropertiesListItem.vue'

export default {
  props: {
    id: String,
    config: Object,
    isSub: {
      type: Boolean,
      default: false
    }
  },

  components: {
    draggable,
    PropertiesListItem
  },

  computed: {
    properties: {
      get () {
        return this.config
          ? Object
            .keys(this.config)
            .map(key => ({ ...this.config[key], key }))
            .sort((a, b) => a.order - b.order)
          : []
      },
      set (items) {
        const result = items.map((item, index) => ({
          ...item,
          order: index
        }))
        const config = {}
        result.forEach(({ key, ...props }) => {
          config[key] = props
        })
        this.$emit('update', config)
      }
    }
  },

  methods: {
    addPropperty () {
      const config = {
        ...this.config,
        [`new-property-${this.properties.length}`]: {
          name: 'New property',
          type: 'STRING',
          order: this.properties.length,
          value: null
        }
      }
      this.$emit('update', config)
    },

    onPropertyUpdate ({ key, ...props }, prevKey) {
      const {
        [prevKey]: property,
        ...config
      } = this.config
      config[key] = props
      this.$emit('update', config)
    },

    onPropertyRemove (key) {
      const { [key]: removed, ...config } = this.config
      this.$emit('update', config)
    }
  }
}
</script>
