<template>
  <div class="color-input">
    <div
      :style="styles"
      :class="classes"
      class="color-input__swatch"
      @click="$emit('select')"
    />
    <input
      type="text"
      class="color-input__field"
      :disabled="isInputDisabled"
      :value="color | hexColor"
      @blur="onColorType">
    <input
      type="text"
      class="color-input__field"
      :disabled="isInputDisabled"
      :value="color | alphaPercent"
      @blur="onAlphaType">
  </div>
</template>

<script>
import color from 'color'

export default {
  props: {
    value: String
  },

  filters: {
    hexColor (hexa) {
      if (hexa) {
        if (hexa.includes('linear')) {
          return 'Linear'
        }
        return hexa.substring(1, 7)
      }
      return '-'
    },

    alphaPercent (hexa) {
      if (hexa) {
        if (hexa.includes('linear')) {
          const deg = parseInt(hexa.substring(hexa.indexOf('(') + 1, hexa.indexOf(',') - 3))
          return `${deg}°`
        }
        const alpha = parseInt(hexa.substring(7, 9), 16)
        return `${Math.round(alpha / 255 * 100)}%`
      }
      return '-'
    }
  },

  computed: {
    color: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    luminosity () {
      return this.color?.startsWith('#')
        ? color(this.color).luminosity()
        : 0.5
    },

    styles () {
      return {
        boxShadow: this.luminosity > 0.8
          ? 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset'
          : null,
        background: this.color
          ? this.color
          : null
      }
    },

    classes () {
      return {
        'color-input__swatch--empty': this.color === null
      }
    },

    isInputDisabled () {
      return this.value &&
        this.value.includes('linear')
    }
  },

  methods: {
    onColorType ($event) {
      const { value } = $event.target
      switch (value.length) {
        case 3:
          this.color = `#${value}${value}FF`
          break
        case 6:
          this.color = `#${value}FF`
          break
        case 7:
          this.color = `${value}FF`
          break
        case 8:
          this.color = `#${value}`
          break
        case 9:
          this.color = value
          break
      }
    },

    onAlphaType ($event) {
      const { value } = $event.target
      const percent = parseInt(value.replace('%', ''))
      const alpha = Math.round(255 * percent / 100).toString(16)
      this.color = `${this.color.substring(0, 7)}${alpha}`
    }
  }
}
</script>

<style lang="scss" scoped>
.color-input {
  height: 28px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgba(0, 0, 0, .1);
  }

  &:focus-within {
    border: 1px solid var(--color-primary);
    outline: 1px solid var(--color-primary);
    outline-offset: -2px;
  }

  &__swatch {
    margin: 8px 0 8px 5px;
    flex-grow: 1;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    border-radius: 1px;

    &--empty {
      position: relative;
      border: 1px solid rgba(0, 0, 0, .1);

      &::after {
        content: "";
        position: absolute;
        top: 46%;
        left: -21%;
        height: 1px;
        width: 145%;
        background-color: red;
        transform: rotate(-45deg);
      }
    }
  }

  &__field {
    cursor: default;
    color: #333;
    padding: 0 0 0 7px;
    height: 28px;
    width: 100%;
    font-size: 11px;
    font-family: 'Inter';
    text-transform: uppercase;

    &:disabled {
      text-transform: none;
    }

    &:last-child {
      width: 44px;
      padding-left: 7px;
      border-left: 1px solid transparent;
    }

    &:focus {
      outline: none;
    }
  }

  &:hover &__field:last-child {
    border-left: 1px solid rgba(0, 0, 0, .1);
  }
}
</style>
