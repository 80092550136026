<template>
  <ColorPickerRange
    :min="0"
    :max="360"
    :color="hueColor"
    :background="hueBackground"
    :value="value.h"
    @input="onUpdate"
  />
</template>

<script>
import ColorPickerRange from './ColorPickerRange.vue'

export default {
  components: {
    ColorPickerRange
  },

  props: {
    value: Object
  },

  data: () => ({
    hueBackground: [
      '#FF0000',
      '#FFFF00',
      '#00FF00',
      '#00FFFF',
      '#0000FF',
      '#FF00FF',
      '#FF0000'
    ]
  }),

  computed: {
    hueColor () {
      return `hsl(${this.value.h},100%,50%)`
    }
  },

  methods: {
    onUpdate (h) {
      this.$emit('input', { ...this.value, h })
    }
  }
}
</script>
