export default {
  computed: {
    isAdmin () {
      return this.$store.getters['auth/isAdmin']
    }
  },

  methods: {
    showFor (role) {
      switch (role) {
        case 'employee':
          return !this.isAdmin
        case 'admin':
          return this.isAdmin
        case 'all':
          return true
      }
    }
  }
}
