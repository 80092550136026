export const populateWebsiteWithOrderFields = (website, order) => {
  return {
    id: website.id,
    group: website.group,
    hash: website.hash,
    deploy: website.deploy,
    firebaseHosting: website.firebaseHosting,
    meta: {
      ...website.meta,
      title: order ? `${order.bridegroom}&${order.bride}` : website.meta.title,
      url: order ? `https://${order.website.subdomain}.invito.link` : null
    },
    general: {
      personalization: order
        ? order.website.personalization
        : website.general.personalization,
      subdomain: order ? order.website.subdomain : null,
      template: order
        ? order.website.template
        : website.general.template,
      package: order
        ? order.package
        : website.meta.package,
      design: website.general.design,
      hideBrandName: website.general.hideBrandName ?? false
    },
    styles: {
      ...website.styles
    },
    builder: {
      publishing: order ? order.website.publishing : false,
      selectedPage: '/',
      selectedWidget: null,
      selectedLang: website.languages[0]
    },
    data: {
      ...website.data,
      ...(order ? { weddingDate: order.weddingDate } : {})
    },
    languages: website.languages,
    pages: website.pages,
    widgets: website.widgets
  }
}
