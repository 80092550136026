import IButtons from './IButtons'
import ICarousel from './ICarousel'
import IColors from './IColors'
import IDate from './IDate'
import IDinner from './IDinner'
import IGift from './IGift'
import IHeart from './IHeart'
import IHero from './IHero'
import IImage from './IImage'
import IList from './IList'
import IMap from './IMap'
import IMessanger from './IMessanger'
import IStopwatch from './IStopwatch'
import IText from './IText'
import IVideo from './IVideo'

const ICONS = {
  IButtons,
  ICarousel,
  IColors,
  IDate,
  IDinner,
  IGift,
  IHeart,
  IHero,
  IImage,
  IList,
  IMap,
  IMessanger,
  IStopwatch,
  IText,
  IVideo
}

export const KEYS = Object.keys(ICONS)

export const registerIcons = (Vue) => {
  KEYS.forEach(key => {
    Vue.component(key, ICONS[key])
  })
}
