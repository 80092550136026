import {
  doc,
  query,
  collection,
  onSnapshot,
  getDoc,
  addDoc,
  setDoc,
  getFirestore,
  deleteDoc
} from 'firebase/firestore'

import { DEFAULT_BALANCE_ID } from '../config/index'

const db = getFirestore()
const COLLECTION = 'balances'

const SET_ITEMS = 'SET_ITEMS'
const SET_UNSUBSCRIBE = 'SET_UNSUBSCRIBE'

export default {
  namespaced: true,

  state: {
    items: [],
    unsubscribe: null
  },

  getters: {
    items: (state) => state.items,
    loaded: (state) => state.loaded,
    default: () => DEFAULT_BALANCE_ID
  },

  mutations: {
    [SET_ITEMS] (state, data) {
      state.items = data
    },

    [SET_UNSUBSCRIBE] (state, data) {
      state.unsubscribe = data
    }
  },

  actions: {
    load ({ state, commit }) {
      if (!state.unsubscribe) {
        const q = query(collection(db, COLLECTION))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = []
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.id,
              ...doc.data()
            }
            items.push(data)
          })
          commit(SET_ITEMS, items)
        })
        commit(SET_UNSUBSCRIBE, unsubscribe)
      }
    },

    async getById (_, id = null) {
      const balanceDoc = await getDoc(doc(db, COLLECTION, id))
      return {
        id: balanceDoc.id,
        ...balanceDoc.data()
      }
    },

    async create (_, { data }) {
      return addDoc(collection(db, COLLECTION), data)
    },

    update (_, { id, data }) {
      return setDoc(doc(db, COLLECTION, id), data, { merge: true })
    },

    delete (_, id = null) {
      return deleteDoc(doc(db, COLLECTION, id))
    }
  }
}
