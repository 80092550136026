export default [
  {
    path: 'finances',
    redirect: 'finances/log',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: 'log',
        component: () => import('../views/log/Index.vue')
      }
    ]
  }
]
