import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject
} from 'firebase/storage'

import { firebaseApp } from '@/util/firebase.js'
import { urlToBlob } from '@/util/utils.js'

const storage = getStorage(firebaseApp)

export default {
  namespaced: true,

  actions: {
    async uploadByUrl ({ dispatch }, { url = null, target = 'default', group = null, name = 'untitled' }) {
      const storageUrl = [target, group, name].filter(item => item).join('/')
      const storageRef = ref(storage, storageUrl)
      const blob = await urlToBlob(url)
      return dispatch('upload', {
        ref: storageRef,
        file: blob
      })
    },

    async upload ({ dispatch }, { ref, file }) {
      dispatch('dashboard/activity/heartbeat', null, { root: true })
      await uploadBytes(ref, file)
      return getDownloadURL(ref)
    },

    async deleteFiles (_, path) {
      try {
        const filesRef = ref(storage, path)
        const { items } = await listAll(filesRef)
        items.forEach(itemRef => {
          deleteObject(itemRef)
        })
      } catch {
        console.error('files/deleteFiles error')
      }
    }
  }
}
