export const RGBAtoCSS = ({ r, g, b, a }, alpha = null) => {
  const alphaValue = alpha !== null ? alpha : a
  return `rgba(${r}, ${g}, ${b}, ${alphaValue})`
}

export const PointsToCSSGradient = (points, angle = 135) => {
  const colors = points
    .map(p => `rgba(${p.r},${p.g},${p.b},${p.a}) ${p.left.toFixed(2)}%`)
    .join(',')
  return `linear-gradient(${angle}deg, ${colors})`
}

export const RGBAtoHSVA = (rgba) => {
  const red = rgba.r / 255
  const green = rgba.g / 255
  const blue = rgba.b / 255
  const xmax = Math.max(red, green, blue)
  const xmin = Math.min(red, green, blue)
  const chroma = xmax - xmin
  const value = xmax
  let hue = 0
  let saturation = 0

  if (chroma) {
    if (xmax === red) { hue = ((green - blue) / chroma) }
    if (xmax === green) { hue = 2 + (blue - red) / chroma }
    if (xmax === blue) { hue = 4 + (red - green) / chroma }
    if (xmax) { saturation = chroma / xmax }
  }

  hue = Math.floor(hue * 60)

  return {
    h: hue < 0 ? hue + 360 : hue,
    s: Math.round(saturation * 100),
    v: Math.round(value * 100),
    a: rgba.a
  }
}

export const HSVAtoRGBA = (hsva) => {
  const saturation = hsva.s / 100
  const value = hsva.v / 100
  let chroma = saturation * value
  const hueBy60 = hsva.h / 60
  let x = chroma * (1 - Math.abs(hueBy60 % 2 - 1))
  const m = value - chroma

  chroma = (chroma + m)
  x = (x + m)

  const index = Math.floor(hueBy60) % 6
  const red = [chroma, x, m, m, x, chroma][index]
  const green = [x, chroma, chroma, x, m, m][index]
  const blue = [m, m, x, chroma, chroma, x][index]

  return {
    r: Math.round(red * 255),
    g: Math.round(green * 255),
    b: Math.round(blue * 255),
    a: hsva.a
  }
}

export const RGBAtoHEX = (rgba) => {
  const alpha = rgba.a * 255 | 0
  const R = rgba.r.toString(16).padStart(2, '0')
  const G = rgba.g.toString(16).padStart(2, '0')
  const B = rgba.b.toString(16).padStart(2, '0')
  const A = alpha.toString(16).padStart(2, '0')
  return '#' + R + G + B + A
}

export const HEXAtoRGBA = (hexa) => {
  const parts = hexa
    .replace('#', '')
    .match(/.{2}/g)
    .map(h => parseInt(h, 16))
  const alpha = parts[3] !== undefined
    ? parts[3] / 255
    : 1
  return {
    r: parts[0],
    g: parts[1],
    b: parts[2],
    a: alpha
  }
}
