import {
  query,
  where,
  addDoc,
  onSnapshot,
  collection,
  getFirestore
} from 'firebase/firestore'

const db = getFirestore()

const STEP = 1000 * 60 * 10
const SET_DATA = 'SET_DATA'
const SET_ITEMS = 'SET_ITEMS'
const SET_UNSUBSCRIBE = 'SET_UNSUBSCRIBE'
const RESET_DATA = 'RESET_DATA'
const COLLECTION = 'activity'

function restoreValue () {
  try {
    const base64 = localStorage.getItem(COLLECTION)
    const value = base64 ? atob(base64) : null
    return value ? JSON.parse(value) : null
  } catch {
    return null
  }
}

function saveValue (value) {
  try {
    const result = btoa(JSON.stringify(value))
    localStorage.setItem(COLLECTION, result)
  } catch {}
}

export default {
  namespaced: true,

  state: {
    items: [],
    unsubscribe: null,
    activity: restoreValue()
  },

  getters: {
    items (state) {
      return state.items
    }
  },

  mutations: {
    [SET_DATA] (state, value) {
      state.activity = value
      saveValue(state.activity)
    },

    [RESET_DATA] (state) {
      state.activity = {
        UID: localStorage.getItem('uid'),
        timestamp: Date.now(),
        score: 0
      }
      saveValue(state.activity)
    },

    [SET_ITEMS] (state, items) {
      state.items = items
    },

    [SET_UNSUBSCRIBE] (state, value) {
      state.unsubscribe = value
    }
  },

  actions: {
    load ({ state, commit }) {
      if (!state.unsubscribe) {
        const today = new Date()
        const from = new Date()
        from.setDate(from.getDate() - 14)
        const q = query(
          collection(db, COLLECTION),
          where('UID', '==', 'HXOGUSSeqbOixJ6svxtQI4nQWnJ2'),
          where('timestamp', '>', from),
          where('timestamp', '<=', today)
        )
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = []
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.id,
              ...doc.data()
            }
            items.push(data)
          })
          commit(SET_ITEMS, items)
        })
        commit(SET_UNSUBSCRIBE, unsubscribe)
      }
    },

    heartbeat ({ state, commit }) {
      if (!state.activity) {
        commit(RESET_DATA)
      }
      if (state.activity) {
        if (Date.now() - state.activity.timestamp > STEP) {
          addDoc(collection(db, COLLECTION), {
            ...state.activity,
            timestamp: new Date(state.activity.timestamp)
          })
          commit(RESET_DATA)
        }
        const data = {
          ...state.activity
        }
        data.score += 1
        commit(SET_DATA, data)
      }
    }
  }
}
