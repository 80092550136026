<template>
  <div class="color-picker">
    <ColorPickerHeader
      v-model="mode"
      :modesSwitch="gradientEnabled"
      @close="$emit('close')"
    />

    <template v-if="mode === 'gradient'">
      <div class="inner">
        <ColorPickerRange
          :color="color"
          v-model="points"
          @select="onPointSelect"
        />
        <ColorPickerRange
          :min="0"
          :max="360"
          color="var(--color-primary)"
          background="var(--color-primary)"
          v-model="angle"
        />
      </div>
    </template>

    <ColorPickerArea v-model="hsva" />

    <div class="inner">
      <ColorPickerHue v-model="hsva" />
      <ColorPickerAlpha v-model="rgba" />
      <ColorPickerInput
        class="space"
        v-model="hexa"
      />
    </div>

    <ColorPickerSwatches
      :items="swatches"
      @input="onSwatchSelect"
    />
  </div>
</template>

<script>
import parser from 'gradient-parser'
import {
  RGBAtoHSVA,
  HSVAtoRGBA,
  RGBAtoHEX,
  HEXAtoRGBA,
  PointsToCSSGradient
} from './utils'
import ColorPickerAlpha from './ColorPickerAlpha.vue'
import ColorPickerArea from './ColorPickerArea.vue'
import ColorPickerHeader from './ColorPickerHeader.vue'
import ColorPickerHue from './ColorPickerHue.vue'
import ColorPickerInput from './ColorPickerInput.vue'
import ColorPickerRange from './ColorPickerRange.vue'
import ColorPickerSwatches from './ColorPickerSwatches.vue'

export default {
  name: 'ColorPicker',

  components: {
    ColorPickerAlpha,
    ColorPickerArea,
    ColorPickerHeader,
    ColorPickerHue,
    ColorPickerRange,
    ColorPickerInput,
    ColorPickerSwatches
  },

  props: {
    value: String,
    gradientEnabled: Boolean,
    swatches: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    mode: 'color',
    color: {
      r: 166,
      g: 108,
      b: 255,
      h: 0,
      s: 0,
      v: 0,
      a: 1
    },
    angle: 135,
    points: [
      {
        left: 0,
        r: 179,
        g: 179,
        b: 179,
        a: 1
      },
      {
        left: 100,
        r: 237,
        g: 202,
        b: 202,
        a: 1
      }
    ]
  }),

  computed: {
    rgba: {
      get () {
        return {
          r: this.color.r,
          g: this.color.g,
          b: this.color.b,
          a: this.color.a
        }
      },
      set (rgba) {
        const hsva = RGBAtoHSVA(rgba)
        this.setColorValues({ ...rgba, ...hsva })
      }
    },

    hsva: {
      get () {
        return {
          h: this.color.h,
          s: this.color.s,
          v: this.color.v,
          a: this.color.a
        }
      },
      set (hsva) {
        const rgba = HSVAtoRGBA(hsva)
        this.setColorValues({ ...hsva, ...rgba })
      }
    },

    hexa: {
      get () {
        return RGBAtoHEX(this.color)
      },
      set (hexa) {
        this.rgba = HEXAtoRGBA(hexa)
      }
    }
  },

  methods: {
    parseValue (value) {
      if (value !== null) {
        if (value.startsWith('#')) {
          this.mode = 'color'
          this.rgba = HEXAtoRGBA(value)
        } else {
          const parsed = parser.parse(value)
          this.points = parsed[0].colorStops.map(item => ({
            r: parseInt(item.value[0]),
            g: parseInt(item.value[1]),
            b: parseInt(item.value[2]),
            a: parseFloat(item.value[3]),
            left: parseFloat(item.length.value)
          }))
          this.rgba = this.points[0]
          this.mode = 'gradient'
        }
      }
    },

    update () {
      const value = this.mode === 'color'
        ? RGBAtoHEX(this.rgba)
        : PointsToCSSGradient(this.points, this.angle)
      if (value !== this.value) {
        this.$emit('input', value)
      }
    },

    setColorValues (values) {
      for (const key in values) {
        this.color[key] = values[key]
      }
    },

    onPointSelect (color) {
      this.rgba = color
    },

    onSwatchSelect (value) {
      this.parseValue(value)
      if (value !== null) {
        this.update()
      } else {
        this.$emit('input', null)
      }
    }
  },

  mounted () {
    if (this.value) {
      this.parseValue(this.value)
    }
  },

  watch: {
    value (value) {
      this.parseValue(value)
    },

    mode (value) {
      switch (value) {
        case 'color':
          // apply first point color to current color
          this.color.r = this.points[0].r
          this.color.g = this.points[0].g
          this.color.b = this.points[0].b
          this.color.a = this.points[0].a
          break
        case 'gradient':
          // apply current color to first point
          this.points[0].r = this.color.r
          this.points[0].g = this.color.g
          this.points[0].b = this.color.b
          this.points[0].a = this.color.a
          break
      }
      this.update()
    },

    gradientEnabled (value) {
      if (!value && this.mode === 'gradient') {
        this.mode = 'color'
      }
    },

    angle () {
      this.update()
    },

    color: {
      handler () {
        this.update()
      },
      deep: true
    },

    points: {
      handler () {
        this.update()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.color-picker {
  width: 240px;
  border-radius: 3px;
  background-color: var(--color-surface);
  box-shadow: 0 0 8px rgba(0, 0, 0, .2);

  .inner {
    padding: 8px 10px;
  }

  .space {
    margin-top: 5px;
  }
}
</style>
