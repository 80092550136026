<template lang="pug">
.toolbar__item.toolbar__item--visible-action
  .toolbar__item-title {{ value[selectedLang] || '-' }}
  .toolbar__item-action
    v-menu(
      left
      offset-y
      :min-width="280"
      :close-on-content-click="false")
      template(v-slot:activator="{ on, attrs }")
        button.btn(
          v-bind="attrs"
          v-on="on")
          v-icon(small) mdi-pencil
      v-card
        v-card-text
          v-text-field.mb-3(
            hide-details="auto"
            label="Label"
            :value="value[selectedLang]"
            @change="onUpdate")
          v-text-field.mb-3(
            hide-details="auto"
            label="URL"
            :value="value.url"
            @change="onLinkUpdate")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    },
    configSelectedLang: {
      type: String,
      default: 'uk'
    }
  },

  computed: {
    ...mapGetters('builder/website', [
      'website'
    ]),

    selectedLang () {
      return this.website
        ? this.website.builder.selectedLang
        : this.configSelectedLang
    }
  },

  methods: {
    onUpdate (value) {
      this.value[this.selectedLang] = value
      this.$emit('update', this.value)
    },

    onLinkUpdate (value) {
      this.value.url = value
      this.$emit('update', this.value)
    }
  }
}
</script>
