import { deepClone, generateId } from '@/util/utils'
import { TYPES } from './consts'

export const CREATE_WIDGET = (widget, pageId, order) => {
  const template = deepClone(widget)
  return {
    id: generateId(),
    order: order,
    enabled: true,
    icon: template.icon ?? null,
    fatherId: template.id,
    pageId: template.type === 'regular'
      ? pageId
      : null,
    name: template.name,
    config: template.config,
    component: template.component,
    type: template.type ?? 'regular'
  }
}

export const ADD_DEFAULT_ITEMS_FOR_WIDGET = (widget) => {
  for (const key in widget.config) {
    if (widget.config[key].type === TYPES.LIST) {
      for (let i = 0; i < widget.config[key].defaultCount; i++) {
        const item = deepClone(widget.config[key].template)
        for (const key in item) {
          if (Array.isArray(item[key].value)) {
            item[key].value = item[key].value[i % item[key].value.length]
          }
        }
        widget.config[key].subConfigs.push(item)
      }
    }
  }
}

export const UPDATE_WIDGET = (source, widget, data) => {
  const website = deepClone(source)
  if (website && widget) {
    const widgetIndex = website.widgets
      .findIndex(item => item.id === widget.id)
    if (widgetIndex !== -1) {
      const widget = website.widgets[widgetIndex]
      UPDATE_WIDGET_CONFIG(widget, data.config)
      for (const key in data) {
        if (key !== 'config') {
          widget[key] = data[key]
        }
      }
      website.widgets[widgetIndex] = widget
      return {
        website
      }
    }
  }
  return {
    website: null
  }
}

export const UPDATE_WIDGET_CONFIG = (source, config) => {
  for (const key in config) {
    for (const option in config[key]) {
      source.config[key][option] = config[key][option]
    }
  }
  return source
}

export const REMOVE_WIDGET = (website, widget) => {
  if (website && widget) {
    const widgetIndex = website.widgets
      .findIndex(item => item.id === widget.id)
    if (widgetIndex !== -1) {
      const removed = website.widgets.splice(widgetIndex, 1)[0]
      return {
        removed,
        website
      }
    }
  }
  return {
    removed: null,
    website: null
  }
}
