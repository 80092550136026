<template lang="pug">
.toolbar__item.toolbar__item--visible-action
  .toolbar__item-title {{ name }}
  .toolbar__item-action
    button.btn(
      v-if="value && !value.locked"
      @click="deleteFile")
      v-icon(
        small
        color="error") mdi-delete
    button.btn(
      v-else
      @click="onUpdate")
      v-icon(small) mdi-upload
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    },
    configSelectedLang: {
      type: String,
      default: 'uk'
    },
    bindValueToLanguage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('builder/website', [
      'website'
    ]),

    selectedLang () {
      return this.website
        ? this.website.builder.selectedLang
        : this.configSelectedLang
    }
  },

  methods: {
    onUpdate () {
      const options = { accept: '.svg,.png,.mp3,.mp4', group: this.$route.params.id }
      this.$files.openAndUpload(options, (data) => {
        if (this.bindValueToLanguage) {
          const value = this.value || {}
          value.locked = false
          value[this.selectedLang] = data
          this.$emit('update', value)
        } else {
          this.$emit('update', data)
        }
      })
    },

    deleteFile () {
      const value = this.bindValueToLanguage
        ? this.value[this.selectedLang]
        : this.value
      this.$files.delete(value)
      this.$emit('update', null)
    }
  }
}
</script>
