import history from './history'
import hosting from './hosting'
import preview from './preview'
import toolbar from './toolbar'
import subdomain from './subdomain'
import website from './website'

export default {
  namespaced: true,

  modules: {
    history,
    hosting,
    preview,
    toolbar,
    subdomain,
    website
  }
}
