<template>
  <div class="d-flex justify-center">
    <v-btn-toggle
      mandatory
      class="py-2"
      :value="items.indexOf(value)"
      @change="onChange"
    >
      <v-btn
        v-for="item in items"
        :key="item"
        small
      >
        {{ item }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },

  data: () => ({
    items: ['uk', 'en', 'ru']
  }),

  methods: {
    onChange ($event) {
      this.$emit('input', this.items[$event])
    }
  }
}
</script>
