import balances from './balances'
import transactions from './transactions'
import statistics from './statistics'

export default {
  namespaced: true,

  modules: {
    balances,
    transactions,
    statistics
  }
}
