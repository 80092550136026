<template>
  <div>
    <div class="toolbar toolbar--dense toolbar--tabs">
      <div class="toolbar__header">
        <div class="toolbar__tabs">
          <div
            v-for="{ label, key } in options"
            :key="key"
            class="toolbar__tab-item"
            :class="{ 'toolbar__tab-item--active': value === key }"
            @click="onSelect(key)"
          >
            {{ label }}
          </div>
        </div>
      </div>
    </div>
    <template v-for="{ key } in options">
      <template v-if="key === value">
        <slot :name="key" />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    value: String
  },

  methods: {
    onSelect (key) {
      this.$emit('input', key)
    }
  }
}
</script>
