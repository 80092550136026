<template>
  <div class="toolbar__item toolbar__item--visible-action">
    <div class="toolbar__item-title">
      {{ name }}
    </div>
    <div class="toolbar__item-action">
      <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="select"
          >
            <div class="select__label">{{ selected | capitalize }}</div>
            <div class="select__icon">
              <svg
                width="8"
                height="7"
                viewBox="0 0 8 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="none"
                  fill-opacity="1"
                  fill-rule="evenodd"
                  fill="currentColor"
                  d="M3.646 5.354l-3-3 .708-.708L4 4.293l2.646-2.647.708.708-3 3L4 5.707l-.354-.353z"
                />
              </svg>
            </div>
          </div>
        </template>

        <div class="styled">
          <v-list dense>
            <v-list-item
              v-for="(option, i) in items"
              :key="i"
              @click="onUpdate(option.key)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ option.name | capitalize }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    value: [String, Object],
    bindValueToLanguage: Boolean,
    options: {
      type: String,
      default: 'default'
    },
    configSelectedLang: {
      type: String,
      default: 'uk'
    }
  },

  computed: {
    ...mapGetters('builder/website', [
      'website'
    ]),

    selectedLang () {
      return this.website
        ? this.website.builder.selectedLang
        : this.configSelectedLang
    },

    items () {
      return this.options
        .split('|')
        .map(key => ({
          key,
          name: key.replaceAll('_', ' ')
        }))
    },

    selected () {
      const value = this.bindValueToLanguage
        ? this.value[this.selectedLang] ?? 'default'
        : this.value
      return value.replaceAll('_', ' ')
    }
  },

  methods: {
    onUpdate (key) {
      const result = this.bindValueToLanguage
        ? {
          ...(typeof this.value === 'object' ? this.value : {}),
          [this.selectedLang]: key
        }
        : key
      this.$emit('update', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  width: 141px;
  height: 28px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-left: 7px;
  padding-right: 4px;
  border: 1px solid rgba(0, 0, 0, .1);

  &__label {
    flex: 1;
    color: rgba(0, 0, 0, 0.9);
    font-size: 11px;
    font-family: 'Inter';
    font-weight: 400;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
  }

  &__icon {
    color: #b3b3b3;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover &__icon {
    color: #333333;
  }
}
</style>
