import { capitalizeFirstLetter } from '@/util/utils.js'

export const registerFilters = (Vue) => {
  const filters = {
    cur (value, sign = '') {
      return sign + value
        .toLocaleString()
        .replace(',', ' ')
    },
    capitalize (value) {
      return capitalizeFirstLetter(value)
    },
    date (value) {
      if (value) {
        return value.substring(0, 10)
      }
      return '-'
    },
    formatedate (value) {
      if (value) {
        return value
          .substring(0, 10)
          .split('-')
          .reverse()
          .join('/')
      }
      return '-'
    },
    currency (item) {
      return item.currency === 'UAH'
        ? `₴${item.price.toLocaleString().replace(',', ' ')}`
        : `$${item.price}`
    },
    cellvalue (value) {
      return value || '-'
    },
    short (value) {
      if (value && value.length > 25) {
        return `${value.substring(0, 25)}...`
      }
      return value
    }
  }

  Object
    .keys(filters)
    .forEach(key => Vue.filter(key, filters[key]))
}
